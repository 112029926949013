<template>
  <div v-if="isShowPopup">
    <!-- 팝업 특성상 리스폰시브가 아닌 모바일/데탑으로 나눔. 모바일일떈 모달로 처리 -->
    <template v-if="!isDesktop && mobileBannerItem">
      <ModalDYDialog v-model:dialog-control="modalPopup" :header="false">
        <div>
          <div
            :style="{ width: computedSizeW, height: computedSizeH, background: mobileBannerItem.background_color ? mobileBannerItem.background_color : 'inherit', margin: '0 auto' }">
            <MiscDYLink v-if="mobileBannerItem.pc_url" :to="mobileBannerItem.pc_url" :title="mobileBannerItem.name">
              <img :src="mobileBannerItem.bannerImage" style="width:100%;">
            </MiscDYLink>
            <img v-else :src="mobileBannerItem.bannerImage">
            <!--   이 윗 부분은 url이동 링크가 없는 순수한 이미지 팝업만 있을 수도 있기 때문     -->
            <p v-if="popupBanner[0].admin_only || popupBanner[0].entries[0]?.admin_only" class="w-full bg-orange-300">현재 관리자만 보이는 상태입니다</p>

            <MiscDYLink v-if="mobileBannerItem.bottom_msg" style="position: absolute; top: 0; right: 0; padding: 5px;"
              :style="{ color: mobileBannerItem.font_color }" @click="closePopUp();">
              <v-icon icon="fas fa-times" />
            </MiscDYLink>
          </div>

          <client-only>
            <div v-if="!isDesktop" class="wr_ppcff">
              <div class="pp_img" @click="closePopUp()" />
              <div class="pp_i_event" style="width: 100%;">
                <div class="con flex flex-wrap" style="height: 55px; width:100%;">
                  <div id="flatChangeAgree" class="col-12" style="border-right: 1px solid #d4d4d4;">
                    <a class="btn_ch"
                      style="padding-top: 18px;font-size: 16px;width: 100%;height:100%; text-align: center;"
                      @click="closePopUp()">
                      오늘 하루 보지 않기
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </client-only>
        </div>
      </ModalDYDialog>
    </template>

    <!-- PC용 팝업 배너 노출. 노드 자체는 구성되고 있다가 경우에 따라 보여줘야 하므로. 보여주는 곳이 이 템플릿이 아닌 외부에 있어서 그러함   -->
    <template v-else>
      <template v-if="deskTopBannerItems && deskTopBannerItems.length > 0 && windowStore.windowWidth > 1700">
        <div v-for="(popup, key) in deskTopBannerItems" :key="`desktop-popup${popup.no}`" class="desktop-popup"
          :style="{ width: computedSizeW, height: computedSizeH, top: calculatePositionTop(key), left: calculatePositionLeft, background: popup.background_color ? popup.background_color : 'inherit' }">

          <p v-if="popupBanner[1].entries[key].admin_only" class="w-full bg-orange-300">아래 배너는 관리자만 보입니다</p>

          <MiscDYLink v-if="popup.pc_url" :title="popup.name"
            :to="popup.pc_url.includes('/v/') ? popup.pc_url.replace(fullDomain + '', '').replace('/v/', '/') : undefined">
            <img :src="popup.bannerImage" style="max-width: 100%;">
          </MiscDYLink>
          <img v-else :src="popup.bannerImage">
          
          <div v-if="popup.bottom_msg" class="mt-1 mr-1" size="x-small" style="position: absolute; top: -18px; right: -7px; padding: 5px; font-size: 30px; cursor: pointer;"
            :style="{ color: popup.font_color }" @click="closePopUp();" icon>
            <v-icon icon="fas fa-times" />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup lang='ts'>
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';
import { useDYComponent } from '~/modules/nuxt3-module-doyac/composables/dy-component';
import { type DYBanner, useDYBanner } from '~/modules/nuxt3-module-doyac/composables/dy-banner';
import { useDYAuth } from '~/modules/nuxt3-module-doyac/composables/auth';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';

library.add(faTimes)

const router = useRouter()
const route = useRoute()

const props = withDefaults(defineProps<{
  popupBanner: DYBanner[] //팝업배너만 보내면 됨
}>(), {

})

const dyComponent = useDYComponent()
const windowStore = useDYWindowStore()
const userStore = useDYUserStore()
const { fullDomain } = useDYAppConfig()
const { hasAuth } = useDYAuth()
const { priorityCheck } = useDYBanner()

// -------------------------인터페이스



// -------------------------데이터

const isAdmin = hasAuth('admin')
const modalPopup = ref(true)

// -------------------------컴퓨티드

const isDesktop = computed(() => {
  return windowStore.isDesktop
})

const isShowPopup = computed(() => {
  //모바일 페이지의 경우 이하 페이지들에선 나오면 안됨
  if(!isDesktop.value && (route.path.includes('/login') || route.path.includes('/cs') || route.path.includes('/moviePlayer') || route.path.includes('/tourPlayer') || route.path.includes('/payOk'))) {
    return false
  }

  if(!isDesktop.value && userStore.isFirstPage) return false

  const cookie = useCookie('SHOW_FLAT_AD' + bannerData.value?.no).value
  return !cookie || cookie !== 'N'
})

const bannerData = computed(() => { // dyBanner의 단위. 이 안의 여러 엔트리들이 있을수는 있음
  const desktopBanner = props.popupBanner[1] //고정으로 1번으로 옴
  const mobileBanner = props.popupBanner[0]
  const currentBanner = isDesktop.value ? desktopBanner : mobileBanner

  if (!currentBanner) return undefined
  if (!currentBanner.available) return undefined
  if (currentBanner.admin_only && !isAdmin) return undefined

  return currentBanner
})

const bannerEntries = computed(() => { //해당 배너의 개별 아이템
  if (!bannerData.value) return undefined

  return priorityCheck(bannerData.value.entries)
})

const computedSizeW = computed(() => {
  if (bannerData.value?.size_w) return bannerData.value.size_w + 'px'
  return '100%'
})

const computedSizeH = computed(() => {
  if (bannerData.value?.size_h) return bannerData.value.size_h + 'px'
  return '100%'
})

const calculatePositionLeft = computed(() => {
  const left = (windowStore.windowWidth - 1100) / 2 - 300 //현재 팝업배너 width가 250이므로 약간 띄우는 공간까지 감안해 300만큼만 차감
  return left + 'px'
})

const deskTopBannerItems = computed(() => { //이미 조건 선별된 애들만 나오는거라
  return bannerEntries.value?.map((entry) => {
    return entry.bannerItem
  })
})

const mobileBannerItem = computed(() => { //모바일의 경우엔 무조건 하나의 배너만 노출할 것이기 때문
  return bannerEntries.value && bannerEntries.value[0] && bannerEntries.value[0].bannerItem
})

// -------------------------메서드

const closePopUp = () => {
  const SHOW_FLAT_AD = useCookie('SHOW_FLAT_AD' + bannerData.value?.no, { maxAge: 86400 })
  SHOW_FLAT_AD.value = 'N'
}

const calculatePositionTop = (key:number) => { //몇번째 노출 배너냐에 따라 달라지므로
  const baseTop = 290
  const realTop = (baseTop * (key+1)) + (25 * (key+1))

  // if (isAdmin) return (realTop + 82) + 'px'

  return realTop + 'px'
}

// -------------------------훅

</script>

<style lang='scss' scoped>
.pp_i_event {
  clear: both;
  margin: 0 auto;
}

.pp_i_event .con {
  height: 32px;
  border-top: 1px solid #D1D1D1;
  font-size: 11px;
  color: #888;
  letter-spacing: -1px;
  background-color: #3A4153;
  background: -webkit-gradient(linear, left top, left bottom, from(#F6F6F6), to(#EBEBEB), color-stop(0.7, #EBEBEB));
  background: -moz-gradient(top, #F6F6F6, #EBEBEB, #EBEBEB);
}

.pp_i_event .con .btn_ch {
  display: inline-block;
  float: left;
  width: 100px;
  height: 30px;
  margin-left: 3px;
}

.pp_i_event .con .btn_cls {
  display: inline-block;
  float: right;
  width: 30px;
  height: 30px;
  margin-right: 3px;
}

.desktop-popup {
  z-index: 100;
  position: absolute;
  left: 110px
}

@media only screen and (max-width: 1840px) {
  .desktop-popup {
    left: 0px
  }
}

@media only screen and (max-width: 1620px) {
  .desktop-popup {
    display: none !important;
  }
}
</style>