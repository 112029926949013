<template>
  <div id="main" class="pb-0">
    <header ref="header" :class="!isTransparent ? 'whiteMode' : 'transparent'"
      :style="data.existTopLine ? 'margin-top:0; !important ' : ''">
      <div ref="actionbarMain" id="actionbar-main"
        class="app-header borderW1 bigTopMenuLayout blackTransShadow hiddenPrintArea "
        :class="(!isTransparent ? 'borderB grayLBorder dark:!bg-[#121212] bg-white' : 'transparent ')"
        style="padding:0; border-bottom: none; top:0;" :style="style">
        <!-- 모바일 화면에서 제목이 길어지는 경우 좌우 흔들림 현상이 생겨서 fixed로 코드 변경 -->

        <div style="display:flex"></div>

        <!-- 기존에는 백엔드에서 홈 관련 데이터를 보냈는데 굳이 그럴 필요 있는가 싶고 그냥 기능 자체는 home으로 보내는 것으로 혹여 나중에 필요해지면 다시 코드 수정-->
        <div :class="windowStore.windowWidth > 1200 ? 'absolute' : ''"
          style="display: flex;align-items: center; height: 100%;">
          <!-- windowStore.windowWidth < 1270 조건이 필요한 이유는 DYLayerLR 사용하는 페이지에서 아이콘이 겹쳐보일 수 있기 때문 -->
          <MiscDYLink
            v-if="windowStore.windowWidth < 1270 && ((!userStore.isFirstPage && isTransparent) || windowStore.canGoBack)"
            class="back-button ml-3 z-[102] cursor-pointer" @click.passive="historyBack">
            <v-icon icon="far fa-arrow-left" style="position:relative; font-size:22px;" />
          </MiscDYLink>

          <MiscDYLink
            v-if="(!(!userStore.isFirstPage && isTransparent) && windowStore.windowWidth <= 1200) || (route.path !== '/' && windowStore.windowWidth > 1200)"
            to="/" style="z-index: 102; display: flex; align-items: center; justify-content: center; min-width:54px;"
            :style="{
      backgroundColor: homeAsUpImage.includes('/renewal_doyac/img/doyac_white_star_logo.svg') ? '#ec1e14' : '',
      paddingLeft: homeAsUpImage.includes('/renewal_doyac/img/doyac_white_star_logo.svg') ? '0' : '12px',
      height: isTransparent ? '30px' : '55px'
    }" class="homeAsUp" ref="topMenuLeftLogo">
            <!--     액션바 타이틀이 지정되어 있고 사이트명이 아닌 경우 api에서 설정된 로고 이미지를 뿌림         -->
            <DYImg v-if="siteName === '도약닷컴'" height="35px"
              :width="homeAsUpImage.includes('/doyacimpl/mini_logo.svg') ? '30px' : '100px'" :src="homeAsUpImage" />
            <DYImg v-if="siteName === '도약아트'" height="35px"
              :width="homeAsUpImage.includes('/doyacimpl/mini_logo.svg') ? '30px' : '120px'" :src="homeAsUpImage" />
            <DYImg v-if="siteName === '마이퍼스트가이드'" height="35px"
              :width="homeAsUpImage.includes('/doyacimpl/mini_logo.svg') ? '30px' : '150px'" :src="homeAsUpImage" />
            <DYImg v-if="siteName === '레슨올'" height="35px"
              :width="homeAsUpImage.includes('/doyacimpl/mini_logo.svg') ? '30px' : '135px'" :src="homeAsUpImage" />
            <!--        특정 타이틀이 지정되어있지 않은 경우 사이트명이 포함된 로고를 뿌림      -->
          </MiscDYLink>
        </div>

        <!-- pc에서 마우스 가져다 대면 하위메뉴들. 이 내용은 사이트마다 다를 수 있어 DYActionbar가 인강/과외에서 나뉘어 불려야 함 -->
        <slot name="top-menu-short-cut">
          <div id="hasShorcut" style="margin:0 auto; height: 100%; align-items: center; z-index: 101; "
            :class="!isTransparent ? 'whiteMode' : 'transparent'">
            <nav>
              <ul class="actionBarMainMenu"
                style="display: flex; position: absolute; left: 0; width: 100%; justify-content: center; height: 55px;">
                <li v-for="(shortCut, key) in shortCutData" :key="key" class="jq_snb_navigation flex justify-center"
                  :style="shortCut.actions && shortCut.actions[0] ? shortCut.actions[0].methodStyle : ''"
                  @mouseenter="showSubNav()" @mouseleave="closeSubNav()">
                  <!-- 본 슬롯은 각 메뉴마다 뭔가 html노드를 추가로 삽입해야 하는 경우 사용할 수 있음 -->
                  <slot name="shortcutMenu" :text="shortCut.text"></slot>
                  <template v-if="siteName === '마이퍼스트가이드'">
                    <MiscDYLink
                      v-if="shortCut.actions && shortCut.actions[0] && shortCut.actions[0].methodName === 'METHOD_GO_TO_ROUTER'"
                      class="actionbar_shortcut main-menu" :to="shortCut.actions[0].methodParams[0]"
                      style="min-width: 140px; text-align: center;" :style="shortCut.actions[0].methodStyle">{{
      shortCut.text
    }}
                    </MiscDYLink>
                    <MiscDYLink
                      v-if="shortCut.actions && shortCut.actions[0] && shortCut.actions[0].methodName === 'METHOD_GO_TO_URL'"
                      class="actionbar_shortcut main-menu" :href="shortCut.actions[0].methodParams[0]"
                      style="min-width: 140px; text-align: center;" :style="shortCut.actions[0].methodStyle">{{
      shortCut.text
    }}
                    </MiscDYLink>
                  </template>
                  <template v-else>
                    <MiscDYLink
                      v-if="shortCut.actions && shortCut.actions[0] && shortCut.actions[0].methodName === 'METHOD_GO_TO_ROUTER'"
                      class="actionbar_shortcut main-menu" :to="shortCut.actions[0].methodParams[0]"
                      :style="shortCut.actions[0].methodStyle">{{ shortCut.text }}</MiscDYLink>
                    <MiscDYLink
                      v-if="shortCut.actions && shortCut.actions[0] && shortCut.actions[0].methodName === 'METHOD_GO_TO_URL'"
                      class="actionbar_shortcut main-menu" :href="shortCut.actions[0].methodParams[0]"
                      :style="shortCut.actions[0].methodStyle">{{ shortCut.text }}</MiscDYLink>
                  </template>
                  <!--    마우스 엔터하면 추가 메뉴들 뜨는 부분 종료     -->
                </li>
              </ul>
            </nav>
          </div>
        </slot>
        <!-- 만약 이부분을 통째로 가져다 쓰려면 슬롯을 넣고, 기본형태(레슨올처럼 하단 안나오고)로 쓸려면 아래것으로 사용 -->

        <!--   타이틀 코드 시 - 해당 사이트에 처음으로 접속한 페이지의 경우와 아닌 경우가 title 노출 방식이 다름. 스토어의 최초 데이터 저장 타이밍 차이    -->
        <DYLazy>
          <div v-if="route.path !== '/'" id="hasTitle" class="title text-black items-center flex !overflow-x-hidden"
            style="font-style: normal; font-size: 24px; align-items: center; max-width: 1100px; display:flex; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; 
              padding-top: 0px;padding-bottom:2px; margin-left: 10px; z-index:102;">
            <span style="font-size: 18px; letter-spacing: -0.5px;" :style="{ width: actionbarTitleWidth }">{{
      mainStore.actionbarTitle ? mainStore.actionbarTitle : actionbarTitle }}</span>
          </div>
        </DYLazy>
        <!--   타이틀 코드 종    -->

        <div id="aaabbb" style="display:flex"></div>
        <!--   오른쪽 사이드의 추가 액션들     -->
        <div class="flex actionItems items-center pr-2 flex-shrink-0 absolute right-0 pl-2 xl:pl-0 z-[105] xl:z-[102]"
          :class="isTransparent ? '' : (isAdmin && windowStore.windowWidth < 769 ? 'bg-[#fff8e9]' : 'bg-white dark:!bg-[#121212] dark:text-white')"
          :style="'display:flex;' + (data.hasShortCut ? 'margin-left:20px;' : '')">
          <!-- white 백그라운드가 있어야만 액션바 타이틀 이상하게 나가는 현상 방지됨 -->

          <!-- v-menu를 최상의 default단계에서 사용할 경우 일부 페이지에서 ssr로 들어왔을 때 parentNode에러가 발생함. 모든 페이지가 그런것은 아님! -->
          <client-only>
            <v-menu v-if="userStore.isHuman && isAdmin && windowStore.windowWidth > 768" v-model="adminMenu"
              :close-on-content-click="false" :close-on-back="false" location="bottom">
              <template v-slot:activator="{ props }">
                <a @click.prevent="" v-bind="props">
                  <v-icon icon="far fa-hammer"
                    style="margin-right: 20px;font-size:27px;vertical-align: bottom; cursor: pointer; color:#c18300" />
                </a>
              </template>

              <v-card min-width="300">
                <v-list>
                  <v-list-item>
                    <div class="flex justify-between">
                      일반: {{ `${fullDomain}/v${route.fullPath}` }}
                      <v-btn class="mr-1" color="blue" @click="clipboard(`${fullDomain}/v${route.fullPath}`)">복사</v-btn>
                    </div>
                  </v-list-item>

                  <v-list-item class="flex justify-between">
                    <div class="flex justify-end">
                      앱 링크: {{ `${fullDomain}/v${appLinkUrl}` }}
                      <v-btn class="mr-1" color="green" @click="clipboard(`${fullDomain}/v${appLinkUrl}`)">복사</v-btn>
                    </div>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </client-only>

          <MiscDYLink v-if="mainPageNotShow && siteName !== '레슨올' && siteName !== '과외하자'" to="/search" title="검색"
            class="dark:!text-white">
            <v-icon icon="fal fa-search"
              style="margin-right: 20px;font-size:27px;vertical-align: bottom; cursor: pointer;" />
          </MiscDYLink>

          <template v-if="userStore.isSignedIn">
            <v-menu contained>
              <template v-slot:activator="{ props }">
                <DYImg v-if="userStore.pictureSet" v-bind="props" :src="userStore.pictureSet.thumbnail_s"
                  :srcset="userStore.pictureSet.dataSet" class="rounded-circle profile-image contrast" width="35px"
                  height="35px" cover style="margin-right: 20px; cursor: pointer; border: 1px solid #d3d3d3;" />
              </template>

              <div class="flex flex-wrap px-4 py-4 rounded-top w-full text-white"
                style="background-color: #f14e00; border-radius: 4px 4px 0 0;">
                <div class="flex flex-wrap items-center mt-1 mb-1 color-white">
                  <span class="mr-2">
                    <DYImg v-if="userStore.pictureSet" :src="userStore.pictureSet.thumbnail_s" cover
                      :srcset="userStore.pictureSet.dataSet" sizes="200px" class="rounded-circle profile-image" />
                  </span>
                  <div class="info-card-text">
                    <div class="fs-lg text-truncate text-truncate-lg"><span style="color:#fff;font-weight:900;" />
                      {{ userStore.nickname ? userStore.nickname : userStore.name }} 님
                    </div>
                    <span class="text-truncate text-truncate-md opacity-80">안녕하세요!</span>
                  </div>
                </div>
              </div>

              <v-list class="!rounded-t-none text-center" density="comfortable" min-width="230">
                <v-list-item :href="!isGawe ? '/mypage' : 'https://www.lessonall.com/mer/mypage/dashboard'"
                  :to="!isGawe ? '/mypage' : ''">
                  <v-list-item-title class="text-sm !text-black">{{ (isGawe || siteName === '마이퍼스트가이드') ? '마이페이지' :
      '마이클래스'
                    }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="userStore.isAndroidApp && siteName === '마이퍼스트가이드'"
                  @click.prevent="openAndroidAppDownloadList">
                  <v-list-item-title class="text-sm !text-black">다운로드함</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="useableCart" to="/payment/cart">
                  <v-list-item-title class="text-sm !text-black">장바구니</v-list-item-title>
                </v-list-item>

                <v-list-item to="/mypage/recentChecked">
                  <v-list-item-title class="text-sm !text-black">{{ siteName === '레슨올' ? '최근 본 요청서' : '최근 본 상품'
                    }}</v-list-item-title>
                </v-list-item>

                <template v-if="siteName === '레슨올'">
                  <v-list-item href="https://www.lessonall.com/mer/mypage/requestFormList?formType=student">
                    <v-list-item-title class="text-sm !text-black">요청서 등록/관리</v-list-item-title>
                  </v-list-item>
                  <v-list-item href="https://www.lessonall.com/mer/mypage/requestFormList?formType=teacher">
                    <v-list-item-title class="text-sm !text-black">전문분야 등록/관리</v-list-item-title>
                  </v-list-item>
                </template>

                <DYLogoutLink v-slot="{ requestLogout }">
                  <v-list-item @click="requestLogout">
                    <v-list-item-title class="text-sm !text-black">로그아웃</v-list-item-title>
                  </v-list-item>
                </DYLogoutLink>
              </v-list>
            </v-menu>

            <MiscDYLink
              v-if="(siteName === '도약닷컴' || siteName === '도약아트' || siteName === '마이퍼스트가이드') && windowStore.windowWidth > 1450"
              class="mr-5 dark:!text-white" :to="isGawe ? '' : '/mypage/messageBox'"
              :href="isGawe ? '/mer/milk/messageBox' : ''">
              <v-icon icon="fal fa-bell" style="font-size:25px;" />
            </MiscDYLink>
            <MiscDYLink v-if="(siteName !== '마이퍼스트가이드' && siteName !== '레슨올') && windowStore.windowWidth > 1350"
              class="dark:!text-white" to="/payment/cart">
              <v-icon class="mr-5" icon="fal fa-shopping-cart" style="font-size:25px;" />
            </MiscDYLink>

          </template>
          <!--      로그인 안되었을때의 템플릿      -->
          <template v-else>
            <span class="mr-5 dark:!text-white" style="position:relative;">
              <DYLoginLink to="/login" class="loginBtn" title="로그인">
                <v-icon icon="fal fa-user-circle" style="font-size:29px;" />
              </DYLoginLink>
            </span>
          </template>

          <span class="mt-1" style="position:relative;">
            <a @click="rightPanelToggle" class="dark:!text-white">
              <v-icon class="mr5" icon="fal fa-list-ul" style="font-size:29px;" />
            </a>
          </span>

          <!--     오른쪽 아이콘 액션들 : 백엔드에서 가져올 때     -->
          <!-- <MiscDYLink v-for="(showMenu, arrKey) in showArr" :key="arrKey" style="display: flex;align-items: center;"
                :title="showMenu.title" @click.prevent="bindAction(showMenu.actions[0])">
                <font-awesome-icon :icon="parsingFontIcon(showMenu.icon)" style="margin-right:10px; font-size: 24px;" />
              </MiscDYLink>
              <MiscDYLink v-if="hideArr.length > 0" href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                <v-icon icon="fal fa-ellipsis-h" style="margin-right: 0; margin-left: -6px; font-size: 24px;" />
              </MiscDYLink>
              <ul class="dropdown-menu pull-right" style="font-weight: 600; color: #fff; text-shadow: none;">
                <li>
                  <MiscDYLink v-for="(hideMenu, arrKey) in hideArr" :key="arrKey" style="display: inline-block;"
                    :title="hideMenu.title" @click.prevent="bindAction(hideMenu.actions[0])">
                    <font-awesome-icon :icon="parsingFontIcon(hideMenu.icon)" style="margin-right:10px; font-size: 24px;" />
                  </MiscDYLink>
                </li>
              </ul> -->
        </div>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBell, faEllipsisH, faListUl, faSearch, faShoppingCart, faUserCircle } from '@fortawesome/pro-light-svg-icons';
// import { faListUl } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeft, faHammer } from '@fortawesome/pro-regular-svg-icons';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { useDYLayoutStore } from '~/modules/nuxt3-module-doyac/stores/layout';
import { type ShortCutMenu, useDYMainStore } from '~/modules/nuxt3-module-doyac/stores/main';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';
import { clipboard } from '~/modules/nuxt3-module-doyac/libs/utils';
import { useDYAuth } from '~/modules/nuxt3-module-doyac/composables/auth';
import { width } from '@fortawesome/pro-light-svg-icons/faPlusSquare';

library.add(faSearch, faUserCircle, faBell, faShoppingCart, faEllipsisH, faListUl, faArrowLeft, faHammer)

const router = useRouter()
const route = useRoute()
const windowStore = useDYWindowStore()
const userStore = useDYUserStore()
const layoutStore = useDYLayoutStore()
const { siteName, ANDROID_APP_PACKAGE_NAME, fullDomain } = useDYAppConfig()
const { getShortCut } = useDYMainStore()
const nuxt = useNuxtApp()
const mainStore = useDYMainStore()
const { isAdmin } = useDYAuth()

const props = withDefaults(defineProps<{
  data: { //페이지 메타 데이터가 될 것
    existTopLine: boolean
    hasShortCut: boolean
    /**
     * 일단은 신버전에선 이 오브젝트의 역할을 축소 예정
     */
    homeAsUp?: {
      image?: string
    }
  }
}>(), {

})

// const emit = defineEmits<{
//   (e: 'update:isOpened', v:boolean): void
// }>()

interface rightIconActionMenus {
  actions: [

  ],
  icon: string,
  title: string,
}

// -------------------------데이터

const actionbarMain = ref<HTMLElement>()
const header = ref()
const lastScrollTop = ref<number>()
const mainPageNotShow = ref(true)
const shortCutData = ref<ShortCutMenu[]>()
const actionbarTitle = ref('')
const topMenuLeftLogo = ref<HTMLElement>()
const adminMenu = ref(false)

// -------------------------컴퓨티드

const appLinkUrl = computed(() => {
  const params = new URLSearchParams()

  if (route.query) {
    Object.keys(route.query).forEach((key: string) => {
      params.append(key, '' + route.query[key])
    })
  }

  params.append('appLink', '1')

  return `${route.path}?${params.toString()}`
})

/**
 * 모바일 환경의 일부 페이지에서 흔들림 현상이 있어서
 */
const actionbarTitleWidth = computed(() => {
  return (windowStore.windowWidth - 75) + 'px'
})

const homeAsUpImage = computed(() => {
  //큰 화면이거나 액션바 타이틀과 사이트명이 다른 경우에
  if (route.path !== '/' && (windowStore.windowWidth > 1200 || mainStore.actionbarTitle !== siteName)) return fullDomain + '/doyacimpl/mini_logo.svg'

  return props.data.homeAsUp?.image ?? '/m/images/top_logo.svg'
})

const useableCart = computed(() => {
  if (siteName === '레슨올') return false
  if (siteName === '마이퍼스트가이드') {
    if (userStore.isAndroidApp || userStore.isIOSApp) return false
  }
  return true
})

const isGawe = computed(() => {
  return siteName === '레슨올' || siteName === '과외하자'
})

const isTransparent = computed(() => {
  return layoutStore.isActionBarTransparent
})

const isNotSsrFrom = computed(() => {
  return nuxt.ssrContext?.noSSR
})

const showArr = computed(() => {
  return [
    {
      actions: [

      ],
      icon: '',
      title: '',
    }
  ]
})

const hideArr = computed(() => {
  return [
    {
      actions: [

      ],
      icon: '',
      title: '',
    }
  ]
})

const mainLogoWidth = computed(() => { //사이트별 로고가 다르기 때문에 사이트마다 개별 지정해야 할 수 있음
  return siteName === '레슨올' ? '120px' : '90px'
})

const actionbarColor = computed(() => {
  return isAdmin.value && windowStore.windowWidth < 769 ? '#fff8e9' : ''
})

const style = computed(() => {
  const value = {
    display: 'flex',
    'align-items': 'center',
    width: '100%',
    '-webkit-transition': 'background-color .5s',
    transition: 'top 0.2s,background-color .5s ease-in-out',
    'z-index': 800,
    backgroundColor: actionbarColor.value
  }

  if (isTransparent) {
    Object.assign(value, { color: '#ffffff !important' })
  } else {
    Object.assign(value, { 'background-color': '#ffffff' })
  }

  return value
})

// -------------------------메소드

const bindAction = (obj: object) => {
  //
}

const closeSubNav = () => {
  //
}

const transparentScrollHandle = () => {

}

const applyActionBarPosition = (scrollTop: number) => {
  if (actionbarMain.value) {
    if (document.documentElement.scrollHeight > 1000 && document.documentElement.offsetHeight > 1200) { //지나치게 이 수치를 낮게 잡을 경우 페이지 전체 height가 작을 경우 덜그럭 거리는 현상이 발생해서 조정함
      if (scrollTop > 100) {
        actionbarMain.value.style.position = 'fixed'
        actionbarMain.value.style.zIndex = '300'
        actionbarMain.value.style.backgroundColor = isAdmin.value && windowStore.windowWidth < 769 ? '#fff8e9' : 'white'

        if (lastScrollTop.value !== undefined && lastScrollTop.value < scrollTop) { //스크롤이 내려가고 있다는 뜻
          actionbarMain.value.style.opacity = '0'
          actionbarMain.value.style.zIndex = '0'
          if (isTransparent.value) {
            header.value?.classList?.remove('defaultTransparent')
            header.value?.classList?.remove('whiteMode')
          }
        } else { //스크롤이 올라가고 있을 때
          actionbarMain.value.style.opacity = '1'
          actionbarMain.value.style.zIndex = '300'
          if (isTransparent.value) {
            header.value?.classList?.add('defaultTransparent')
            header.value?.classList?.add('whiteMode')
            actionbarMain.value?.classList?.add('defaultTransparent')
            actionbarMain.value?.classList?.remove('transparent')
            header.value?.classList?.remove('transparent')
          }
        }
      } else {
        actionbarMain.value.style.position = 'relative'
        actionbarMain.value.style.zIndex = '101'
        actionbarMain.value.style.opacity = '1'

        if (isTransparent.value) {
          header.value?.classList?.remove('defaultTransparent')
          header.value?.classList?.remove('whiteMode')
          header.value?.classList?.add('transparent')
          actionbarMain.value?.classList?.remove('defaultTransparent')
          actionbarMain.value?.classList?.add('transparent')
        }
      }
    } else {
      actionbarMain.value.style.position = 'relative'
      actionbarMain.value.style.zIndex = '101'
      actionbarMain.value.style.opacity = '1'

      if (isTransparent.value) {
        header.value?.classList?.remove('defaultTransparent')
        header.value?.classList?.remove('whiteMode')
        header.value?.classList?.add('transparent')
        actionbarMain.value?.classList?.remove('defaultTransparent')
        actionbarMain.value?.classList?.add('transparent')
      }
    }
  }
  lastScrollTop.value = scrollTop
}

const historyBack = () => {
  if (process.client) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (window.android && window.android.goBack) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      window.android.goBack()
      return
    }
  }
  return router.back()
}

const openAndroidAppDownloadList = () => {
  if (userStore.isAndroidApp) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.android.openDownloadList()
  } else {
    location.href = `market://details?id=${ANDROID_APP_PACKAGE_NAME}`
  }
}

const parsingFontIcon = (icon: string) => {
  // const array = icon?.split(' ')
  // array[1] = array[1]?.replace('fa-', '')
  // return array
}

const rightPanelToggle = () => {
  layoutStore.isRightPannelVisible = !layoutStore.isRightPannelVisible
}

/**
 * 숏컷 메뉴에 마우스를 올렸을 때 보여주는 하위 메뉴들
 */
const showSubNav = () => {
  //
}


try {
  const shortCutRes = await getShortCut()
  if (shortCutRes) {
    shortCutData.value = shortCutRes
  }
} catch (e: any) {

}


// shortCutData.value = extractDataOrFailure(response)

let observer: MutationObserver

onMounted(async () => {
  await nextTick()

  actionbarTitle.value = mainStore.actionbarTitle ? mainStore.actionbarTitle : document.title

  observer = new MutationObserver((mutations) => {
    actionbarTitle.value = mainStore.actionbarTitle ? mainStore.actionbarTitle : document.title
  });

  const titleNode = document?.querySelector("title")
  if (titleNode) {
    observer.observe(titleNode, {
      subtree: true,
      characterData: true,
      childList: true,
    })
  }

  const io = (() => {
    if (process.client && window.IntersectionObserver) {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          // console.log('엔트리',entry)
          if (entry.target.id) {
            if (entry.isIntersecting) {
              entry.target?.classList?.add('isVisible')
            } else {
              entry.target?.classList?.remove('isVisible')
            }
          }
        })
      })
    }
    return undefined
  })()

  window.addEventListener('scroll', () => {
    const scrollTop = window.scrollY // 스크롤 이벤트마다 탑을 새로 재기 위함

    // if (!windowStore.isDesktop) { hasScrolled(scrollTop) } // 모바일일때는 스크롤 내리면 기본 액션바 소멸시킴

    if (scrollTop > 100) {
      applyActionBarPosition(scrollTop) // 스크롤 여부에 따른 액션바 형태 변환

      if (route.path === '/' && topMenuLeftLogo instanceof HTMLElement && !topMenuLeftLogo?.classList?.contains('top-menu-left-logo-showing')) {
        topMenuLeftLogo.style.display = 'flex'
        topMenuLeftLogo?.classList?.add('top-menu-left-logo-showing')
      }

      mainPageNotShow.value = true
    } else {
      applyActionBarPosition(scrollTop)

      // if (route.path === '/') { mainPageNotShow.value = false }
    }
  })


})

onUnmounted(() => {
  if (observer) observer.disconnect()
})

</script>

<style lang="scss" scoped>
header .app-header.transparent .dropdown-item a,
header .app-header.transparent .dropdown-item a:hover,
header .app-header.transparent .dropdown-item a:link {
  color: #757575 !important;
}

header.whiteMode .app-header.nav-up,
header.transparent .app-header.nav-up {
  top: -90px;
}

header.whiteMode #actionbar-main.app-header.nav-up {
  position: inherit !important;
}

//header.whiteMode #actionbar-main.app-header.nav-up.isVisible{position:inherit!important;}
header.transparent {
  .app-header {
    height: 0px;
    padding: 0px 0 0px 12px;
  }

  .hasShorcut {
    display: inline-block;
  }

  .back-button,
  .homeAsUp,
  .actionItems,
  .title {
    margin-top: 55px
  }

  .back-button {
    color: #fff
  }

  #hasTitle {
    margin-top: 62px !important;
  }
}

header.whiteMode {
  .back-button {
    color: #545152
  }

  .hasShorcut {
    display: flex;
  }

  .app-header {
    height: 55px;
    padding: 0px 0 0px 12px;
  }
}

@media screen and (max-width: 1400px) {

  //1650에서 1400으로 변경
  header .headerDefaultMenu-lg {
    display: none;
  }
}

.top_picture_list {
  position: absolute;
  top: 350px;
}

//숏컷 메뉴일 경우 어느 시점부터 보였다 안 보였다 할 것인지 부분 시
@media screen and (max-width: 1200px) {
  #hasTitle {
    // display: block !important;
    width: 100%;
  }

  #hasShorcut {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  #hasTitle {
    display: none !important;
  }
}

//숏컷 메뉴일 경우 어느 시점부터 보였다 안 보였다 할 것인지 부분 종

@media screen and (max-width: 800px) {
  #hasTitle {
    text-align: left !important;
    margin-left: 10px !important;
  }

  //a.homeAsUp {display: none;}
}

//왼쪽 메인 로고가 처음엔 보이다가 사이즈가 줄면 숏컷 메뉴를 위해 없어지고 아예 모바일 탑 메뉴가 나올 정도로 작아지면 다시 보이도록. 숏컷을 안쓸 경우 조정이 필요함
header .loginSubMenu button:link {
  color: #757575 !important
}

header .loginSubMenu button:hover {
  color: #757575 !important
}


.actionItems a {
  color: #222;
}

.transparent .actionItems a,
.transparent .actionBarMainMenu a {
  color: white;
}

.transparent:deep(span) {
  color: white;
}

.actionItems a span {
  color: #222 !important;
}

.transparent:deep(li) {
  background-color: unset !important;
}

.transparent:deep(li a) {
  background-color: unset !important;
}

.force_white:deep(li a) {
  color: #4d4d4d !important;
}

#loginSubMenu .v-btn.dropdown-item:before {
  background-color: inherit;
}

button,
html [type="button"] {
  -webkit-appearance: none !important;
}

.jq_snb_navigation {
  display: flex;
  align-self: center;
  padding: 17px 0;
}

.jq_snb_navigation:hover a {
  color: #f63d8b !important
}

.actionbar_shortcut {
  height: 100%;
  font-size: 15px;
  padding: 0px 25px;
  color: #4d4d4d;
  display: inline-block;
  font-weight: 600;
}

@media (max-width: 1380px) {
  .actionbar_shortcut {
    padding: 0px 20px;
  }
}

.blackTransShadow {
  box-shadow: 0 0 7px rgb(0 0 0 / 10%);
}

.profile-image {
  width: 3.125rem;
  height: 3.125rem;
}

.rounded-circle {
  border-radius: 50% !important;
}

.selectedMenu {
  background-color: #e36082;
}

.selectedMenu:deep(a),
.selectedMenu:deep(a):hover {
  color: white !important;
}

header .app-header.transparent {

  a:not(.dropdown-item),
  a svg,
  a:hover:not(.dropdown-item),
  a:link:not(.dropdown-item),
  .actionbar_shortcut.main-menu {
    color: #fff !important;
  }
}

.actionbar_shortcut.main-menu {
  font-size: 16px;
}

.defaultTransparent.whiteMode .transparent .actionBarMainMenu a {
  color: black;
}
</style>