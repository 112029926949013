import { useDYFetch } from "~/modules/nuxt3-module-doyac/composables/fetch";

export function useDYDefault() {
  const userStore = $dy__useDYUserStore()

  /**
   * 기존 세션 방식 페이지도 제대로 동작시키기 위한 메서드.
   */
  const syncLegacy = () => {
    // 관리자 강제 접속인 경우 구 세션 동기화를 하면 안됨!! 동기화하면 관리자 페이지 로그인이 풀림.
    const mockingToken = useCookie('auth._token.laravelPassport_mocking')
    if (mockingToken.value) {
      return
    }

    if (userStore.isSignedIn) {
      try {
        const response = useDYFetch<string>('/mer/csrf', { method: 'get' }).then((csrfResponse) => {
          useDYFetch('/mer/oauth/sync-legacy', {
            method: 'post', body: undefined, headers: { 'X-CSRF-TOKEN': csrfResponse.data.value ?? '' }
          }).then(() => {
            //
          })
        })
      } catch (e: any) {
        console.error('DYDefault2', e)
        $dy__notifyError(e)
      }
    }
  }

  return {
    syncLegacy
  }
}