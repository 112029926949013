import type { ApiListStructure } from "./common"
import { extractDataOrFailure, useDYFetchWithToken } from "./fetch"

export interface DYMessageHistory {
  btn: { btn1Text: string, btn2Text: string|null, btn1Href: string|null, btnType: "buttonOne"|'buttonTwo', btn2Href: string|null }
  custom1: string|null
  custom2: string|null
  custom3: string|null
  file1: string|null
  icon: { class: string, style: { color: string, fontSize: string }, title: null }
  intervalTime: string
  isAdmin: boolean
  isMember: boolean
  messageBoxType: string
  messageNo: number
  method: string
  newArrival: boolean
  no: number
  opened: boolean
  receiverMemberNo: number
  result: string
  sendTime: string
  subject: null
  systematizedName: string
  text: string
  title: string
  treatAsOpened: boolean
  unreadState: string
  url: string
}

export interface DYMessage {
  domain: string
  fileCount: number
  gubun: string
  massage: string
  messageType: string
  no: number
  receiverPhone: string
  resultCode: string
  sendDate: string
  sendStat: string
  senderPhone: string
  serviceProvider: string
  /**
   * 예약 타입인 경우에만 있을지도..?
   */
  methodType: string|null
}

export interface DYMessageGetListParams {
  year: number
  month: string
  keyword?: string
  resultCode?: string
  messageType?: string
  showQueue?: string
  sendDate?: string
  /**
   * 타입별 카운트를 구하고자 하는 경우
   */
  typeCount?: boolean
}

export interface DYMessageHistoryShowParams {
  perPage?: number
  page?: number
  /**
   * 유저가 요청한 부분까지는 읽은 처리로 할 것인지
   */
  checkOpenMessage?: boolean
  /**
   * 자기것만 가져오는지
   */
  my?: 'y'
  opened?: number
  /**
   * 쪽지함에 남는지
   */
  visibleOnWeb?: number
  /**
   * 오늘 볼 수 있는지
   */
  untilToday?: boolean
}

export function useDYMessageApi() {
  const getList = async (params?: DYMessageHistoryShowParams) => {
    const response = await useDYFetchWithToken<ApiListStructure<DYMessageHistory>>('/mer/api/doyac-message_history', {params})
    return extractDataOrFailure(response)
  }

  const getMessageBox = async (params?: DYMessageHistoryShowParams) => {
    const response = await useDYFetchWithToken<ApiListStructure<DYMessageHistory>>('/mer/api/doyac-message_history/messageBox', {params})
    return extractDataOrFailure(response)
  }

  /**
   * 메시지 히스토리 기반이 아닌 lg sms,mms,세종의 메시지 발송 로그 리스트들
   * @param params 
   * @returns 
   */
  const getMessageLogs = async (params: DYMessageGetListParams) => {
    const response = await useDYFetchWithToken<ApiListStructure<DYMessage>>('/mer/api/messages', {params})
    return extractDataOrFailure(response)
  }

  /**
   * dy_message db기반으로 요청
   * @param params 
   * @returns 
   */
  const getRecentMessage = async (keyword?:string) => {
    const params = {...{}, keyword}
    const response = await useDYFetchWithToken<any[]>('/mer/api/messages/recent-list', {params})
    return extractDataOrFailure(response)
  }

  const sendMessage = async (body?:any) => {
    const response = await useDYFetchWithToken<any[]>('/mer/api/messages/send', {method:'post', body})
    return extractDataOrFailure(response)
  }

  return {
    getList, getMessageBox, getMessageLogs, getRecentMessage, sendMessage
  }
}