<template>
  <div>
    <!--  설정된 별도의 배너가 없을 때 기본 탑 코드 시  -->
    <template v-if="siteName !== '마이퍼스트가이드'">
      <div @click="router.push('/introduce')" v-if="windowStore.windowWidth > 1200" class="campaign_close cursor-pointer" style="background-color: #383837;">
        <DYImg :src="fullDomain + '/doyacimpl/basic_top_banner_pc.webp'" />
      </div>
      <!-- <div v-show="!showLayer" class="campaign_close" style="display: none;">
        <div class="wr_campaign">
          <strong class="cont_l"><img :src="fullDomain + '/renewal/img/top_banner/c_cont1.png'" alt="도약닷컴"></strong>
          <p class="cont_r">
            <img :src="fullDomain + '/renewal/img/top_banner/c_cont4.png'"
              alt="기타 독학 통기타 배우기 피아노 작곡 음악이론 우쿨렐레 색소폰강의 CCM 클래식">
          </p>
          <MiscDYLink id="headerProOpenBtn" class="btn_open" @click="headerProOpen">
            <img :src="fullDomain + '/renewal/img/top_banner/btn_open.png'" alt="열기">
          </MiscDYLink>
        </div>
      </div>
      <div v-show="showLayer" class="campaign_open h-[478px]" style="display:none">
        <div class="bg_r" style="z-index:2;" />
        <div class="wr_campaign" style="z-index:3;">
          <MiscDYLink id="headerProCloseBtn" class="btn_close" @click="headerProClose">
            <img :src="fullDomain + '/renewal/img/top_banner/btn_close.png'" alt="닫기">
          </MiscDYLink>
          <div class="wr_slide">
            <MiscDYLink class="btn_prev" style="cursor: pointer;" @click="btnPrev">
              <img :src="fullDomain + '/renewal/img/top_banner/btn_prev.png'" alt="이전">
            </MiscDYLink>
            <MiscDYLink class="btn_next" style="cursor: pointer;" @click="btnNext">
              <img :src="fullDomain + '/renewal/img/top_banner/btn_next.png'" alt="다음">
            </MiscDYLink>
            <p v-if="campaignMain" id="contSlideImg" class="cont_slide">
              <img :src="campaignMain.src" :alt="campaignMain.alt">
            </p>
          </div>

          <ul class="wr_tab">
            <li>
              <MiscDYLink class="headerProImgTab campaignImg0" @click="showCampaign(0)">
                <img :src="currentBannerNum === 0 ? fullDomain + '/renewal/img/top_banner/tab1on.png' : fullDomain + '/renewal/img/top_banner/tab1.png'" alt="업계1위">
              </MiscDYLink>
            </li>
            <li>
              <MiscDYLink class="headerProImgTab campaignImg1" @click="showCampaign(1)">
                <img :src="currentBannerNum === 1 ? fullDomain + '/renewal/img/top_banner/tab2on.png' : fullDomain + '/renewal/img/top_banner/tab2.png'" alt="과외특화">
              </MiscDYLink>
            </li>
            <li>
              <MiscDYLink class="headerProImgTab campaignImg2" @click="showCampaign(2)">
                <img :src="currentBannerNum === 2 ? fullDomain + '/renewal/img/top_banner/tab3on.png' : fullDomain + '/renewal/img/top_banner/tab3.png'" alt="업계유일">
              </MiscDYLink>
            </li>
            <li>
              <MiscDYLink class="headerProImgTab campaignImg3" @click="showCampaign(3)">
                <img :src="currentBannerNum === 3 ? fullDomain + '/renewal/img/top_banner/tab4on.png' : fullDomain + '/renewal/img/top_banner/tab4.png'" alt="수상기록">
              </MiscDYLink>
            </li>
            <li>
              <MiscDYLink class="headerProImgTab campaignImg4" @click="showCampaign(4)">
                <img :src="currentBannerNum === 4 ? fullDomain + '/renewal/img/top_banner/tab5on.png' : fullDomain + '/renewal/img/top_banner/tab5.png'" alt="품질인증">
              </MiscDYLink>
            </li>
            <li v-if="dbDomain === 'doyacweb' || dbDomain === 'doyacart'">
              <MiscDYLink class="headerProImgTab campaignImg5" @click="showCampaign(5)">
                <img :src="currentBannerNum === 5 ? fullDomain + '/renewal/img/top_banner/tab6on.png' : fullDomain + '/renewal/img/top_banner/tab6.png'">
              </MiscDYLink>
            </li>
          </ul>
        </div>
      </div> -->
      <!--  설정된 별도의 배너가 없을 때 기본 탑 코드 종  -->
    </template>
  </div>
</template>

<script setup lang='ts'>
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { useDYComponent } from '~/modules/nuxt3-module-doyac/composables/dy-component';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';

const router = useRouter()
const route = useRoute()

const props = withDefaults(defineProps<{
  useHref?: boolean
}>(), {
  useHref: () => false,
})

const dyComponent = useDYComponent()
const { siteName, fullDomain, dbDomain } = useDYAppConfig()
const windowStore = useDYWindowStore()

// -------------------------인터페이스

// -------------------------데이터

const showLayer = ref(false)
const currentBannerNum = ref(0) // 현재의 캠페인 넘버
const currentTab = ref(0) // 현재의 우측탭 넘버

// -------------------------컴퓨티드

const campaignMain = computed(() => {
  let result

  switch (currentBannerNum.value) {
    case 0: result = { src: '/renewal/img/top_banner/cont_slide1.png', alt: siteName }; break
    case 1: result = { src: '/renewal/img/top_banner/cont_slide2_20141231.png', alt: siteName + '만의 서비스' }; break
    case 2: result = { src: '/renewal/img/top_banner/cont_slide3.png', alt: '스마트폰APP서비스' }; break
    case 3: result = { src: '/renewal/img/top_banner/cont_slide4.png', alt: '서비스 만족대상 수상' }; break
    case 4: result = { src: '/renewal/img/top_banner/cont_slide5.png', alt: '콘텐츠 제공 품질인증 획득' }; break
    case 5: result = { src: '/renewal/img/top_banner/cont_slide6.png', alt: '서비스 만족대상 수상/컨텐츠 제공 품질인증 획득' }; break
  }

  return result
})

// -------------------------메서드

const btnPrev = () => {
  showCampaign(currentBannerNum.value - 1)
}

const btnNext = () => {
  showCampaign(currentBannerNum.value + 1)
}

const headerProOpen = () => {
  showLayer.value = true
}

const headerProClose = () => {
  showLayer.value = false
}

const showCampaign = (num: number) => {
  if (num > 5) num = 0
  if (num < 0) num = 5

  if (currentBannerNum.value !== num) currentBannerNum.value = num
}

// -------------------------훅

</script>

<style lang='scss' scoped>
#TnbBanner {
  max-height: 90px;
  /*min-width:1168px;*/
  background-color: #E4EFF1
}

#TnbBanner #tnbtop {
  width: 920px;
  margin: 0 auto
}

#TnbBanner #tnbtop_op {
  overflow: hidden;
  position: relative;
  width: 920px;
  margin: 0 auto
}

#TnbBanner #tnbtop_op a {
  float: left
}

#TnbBanner #tnbtop_op .ad_line {
  display: block;
  position: absolute;
  left: 460px;
  top: 0;
  width: 2px;
  height: 68px;
}

#TnbBanner #tnbtop_op .BtnTnbClose {
  display: block;
  position: absolute;
  right: 0;
  top: 20px;
  width: 29px;
  height: 29px;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer
}

.wr_campaign {
  position: relative;
  width: 1100px;
  margin: 0 auto
}

.campaign_close {
  height: 79px;
  border-bottom: 1px solid #302f2f;
  overflow: hidden;
  z-index: 100;
}

.campaign_close .cont_l {
  float: left
}

.campaign_close .cont_r {
  float: right;
  margin-right: 157px
}

.campaign_close .btn_open {
  position: absolute;
  top: 10px;
  right: 0
}

.campaign_open {
  height: 429px !important;
  background: url('https://www.doyac.com/renewal/img/top_banner/bg1.gif') 0 0 repeat;
  border-bottom: 1px solid #302f2f;
  overflow: hidden;
  z-index: 100;
  min-width: 1100px;
  width: 100%;
}

.campaign_open .bg_r {
  position: absolute;
  height: 429px;
  top: 0;
  right: 0;
  left: 47%;
  margin-left: 314px;
  border-left: 1px solid #323230;
  background: url('https://www.doyac.com/renewal/img/top_banner/bg2.gif') 0 0 repeat;
  z-index: 0
}

.campaign_open .wr_slide {
  position: relative;
  float: left;
  width: 796px;
}

.campaign_open .wr_slide .cont_slide {
  width: 643px;
  margin: 0 auto;
  overflow: hidden
}

.campaign_open .wr_slide .btn_prev {
  position: absolute;
  top: 189px;
  left: 23px
}

.campaign_open .wr_slide .btn_next {
  position: absolute;
  top: 189px;
  right: 23px
}

.campaign_open .wr_tab {
  float: right;
  margin-top: 52px;
  width: 252px;
  z-index: 2
}

.campaign_open .wr_tab li {
  margin-bottom: 20px
}

.campaign_open .btn_close {
  position: absolute;
  top: 360px;
  right: 0;
  z-index: 2;
}

#mobile-top-banner {
  position: relative;
  align-items: center;
  justify-content: center;
}

.mobile-top-banner-side {
  display: inline-block;
  width: 300px;
  height: 79px;
  flex: 1;
}

@media (max-width: 439px) {
  .mobile-top-banner-side {
    display: inline-block;
    width: 300px;
    flex: 1;
  }
}

@media (max-width: 1445px) {
  .dDayHMS {
    display: none;
  }
}

@media (min-width: 1315px) {
  .dDayHMS {
    display: block;
  }
}

@media (max-width: 1311px) {
  .dDayContent {
    left: 1000px;
  }
}

@media (max-width: 1089px) {
  .dDayContent {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .fixed-header #header {
    height: 49px;
  }

  #campaignCloseLayer {
    display: none
  }
}

@media screen and (min-width: 800px) and (max-width: 1099px) {
  .fixed-header #header {
    height: 49px;
  }

  #campaignCloseLayer {
    display: none
  }
}

@media screen and (min-width: 1100px) {
  .topBannerImage {
    min-height: 79px;
  }

  .campaign_close {
    min-width: 1100px;
  }

  .wr_campaign {
    width: 1100px;
  }

  #campaignCloseLayer {
    display: block;
    height: 79px;
    width: 100%;
  }

  .fixed-header #header {
    height: 128px;
  }

  .fixed-header #main {
    margin-top: 128px;
  }
}
</style>