<template>
  <div v-if="windowStore.windowWidth > 1200" class="doyac_footer hiddenPrintArea" id="doyac_footer" style="z-index:100;">
    <!--  트레이드 마크  -->
    <div class="doyac_trade text-sm" style="margin-bottom: 25px;">
      <p>{{ siteName }}{{ siteName === '도약닷컴' || siteName === '레슨올' ? '은' : '는' }} 여러분 삶의 도약을 위해 최선을 다하겠습니다. 언제나 곁에서 도울게요,
        약속해요!! </p>
    </div>
    <!-- // 트레이드 마크  -->
    <div class="doyac_lump_footer">
      <p>
        <template v-if="siteName !== '레슨올'">
          <MiscDYLink to="/introduce">{{ siteName }} 소개</MiscDYLink>|
        </template>

        <MiscDYLink :to="'/customerCenter/privacy'">
          <strong>개인정보처리방침</strong>
        </MiscDYLink>|
        <MiscDYLink :to="'/customerCenter/agree'">이용자 약관</MiscDYLink>|
        <template v-if="siteName === '레슨올'">
          <MiscDYLink href="https://www.lessonall.com/adcenter/ad_guide_tab.php">광고문의</MiscDYLink>|
        </template>
        <template v-else>
          <MiscDYLink to="/creator/">강사모집</MiscDYLink>|
        </template>
        <template v-if="siteName !== '레슨올'">
          <MiscDYLink to="/customerCenter/emailCollectReject">메일 수집거부</MiscDYLink>|
        </template>

        <a v-if="siteName !== '레슨올'" :href="'https://doyac.channel.io'" target="_blank">제휴문의</a>
        <a v-else href="https://www.lessonall.com/renewal_gawe/04customerCenter/freegesi_tab.php?an=473459_2">제휴문의</a>

      </p>
      <div class="footer_add">
        <DYImg src="/renewal/img/logo_transparent.png" width="140px" style="position:absolute;left:0;" />
        <p class="address">
          경기도 부천시 원미구 상동로 105 현해프라자 4층 (우:14543)&nbsp;&nbsp;|&nbsp;&nbsp;<strong>(주)도약</strong><br>
          사업자등록번호 130-86-80648&nbsp;&nbsp;|&nbsp;&nbsp;통신판매업신고 제 2013-경기부천-0917호
          <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1308680648" target="_blank">
            <span class="px-1 rounded"
              style="font-size: 11px; font-weight: 400; margin-right: 5px;color: #FFFFFF; background-color: #414b6a;">정보조회</span>
          </a><br>
          대표이사 : 심병욱, 심병민&nbsp;&nbsp;|&nbsp;&nbsp;Tel {{ ADMIN_PHONE_NUMBER }}&nbsp;&nbsp;|&nbsp;&nbsp;Fax
          {{ CUSTOMER_FAX_NUMBER }}&nbsp;&nbsp;&nbsp;&nbsp;
          |&nbsp;&nbsp;<a :href="`mailto:${CUSTOMER_EMAIL_ADDRESS}`" title="문의메일 보내기">{{ CUSTOMER_EMAIL_ADDRESS }}</a>
        </p>
        <p>Copyright ⓒ {{ new Date().getFullYear() }} {{ siteName }}. All Rights Reserved.</p>
      </div>
      <div class="footer_cs">
        <p><span>고객센터 </span>&nbsp;:&nbsp; 월~목 10:00 ~ 17:00 / 금 10:00 ~ 13:00(공휴일 제외)<br> 점심 시간 : 13:00 ~ 14:00</p>
        <div class="tel flex">
          <span>T <strong>{{ ADMIN_PHONE_NUMBER }}</strong></span>
          <MiscDYLink :to="siteName === '레슨올' ? '/customerCenter' : '/cs'" class="btn !flex items-center justify-center">고객센터
          </MiscDYLink>
        </div>
      </div>

    </div>
    <div v-if="siteName !== '마이퍼스트가이드'" class="footer_mark mx-auto max-w-[1100px] -mt-16 pb-8" style="top:165px;">
      <ul class="flex">
        <li class="col">
          <MiscDYLink to="/board/dh_001">
            <img :src="`${fullDomain}/renewal_doyac/img/footer/footer_hanguk.png`" alt="">
          </MiscDYLink>
          <p>
            {{ siteName === '레슨올' ? '6년 연속' : '7년 연속(2010-2017)' }}<br>
            대한민국 서비스만족 대상<br>
            {{ siteName === '레슨올' ? '' : '교육서비스 이러닝 부문 1위' }}
          </p>
        </li>
        <li class="col">
          <a href="/renewal/img/contents_doyac.png" target="_blank">
            <img :src="`${fullDomain}/renewal_doyac/img/footer/footger_contents.png`" alt="">
          </a>
          <p>
            한국 데이터 진흥원<br>
            콘텐츠 제공 서비스 품질인증<br>
            우수 컨텐츠 인증
          </p>
        </li>
        <li v-if="siteName === '도약닷컴'" class="col">
          <img :src="`${fullDomain}/renewal_ingang/img/footer/footer_copyright.png`" alt="저작권">
          <p>
            한국 음악 저작권 협회<br>
            음악저작권<br>
            승인 확인
          </p>
        </li>
        <li class="col">
          <a href="#"
            onclick="window.open('https://pgweb.dacom.net/pg/wmp/mertadmin/jsp/mertservice/s_escrowYn.jsp?mertid=lessonallshop0', '', 'scrollbars=no,status=no,toolbar=no,resizable=no,location=no,menu=no,width=340,height=270'); return false;">
            <img :src="`${fullDomain}/renewal_doyac/img/footer/footer_tosspayments.png`" alt="토스페이먼츠">
          </a>
          <p>
            토스페이먼츠 구매안전 서비스<br>
            결제대금예치업 등록번호<br>
            02-006-00059
          </p>
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="page-footer d-flex flex-wrap" style="position: relative; background-color: #2a2725;">
    <div class="col-12">
      <div class="flex" style="background-color: #373737; margin:0 auto; padding: 5px 0;">
        <ul class="w-full" style="margin: 0 auto; padding: 0;">

          <template v-if="siteName === '레슨올'">
            <li style="list-style: none; width: 25%; float: left;">
              <MiscDYLink to="/requestForm/teacher/list" style="color: inherit;">
                <span style="display: block; padding: 5px;">
                  <v-icon icon="fal fa-users-class" class="text-xl" />
                </span>
                전문가찾기
              </MiscDYLink>
            </li>
            <li style="list-style: none; width: 25%; float: left;">
              <MiscDYLink to="/requestForm/student/list" style="color: inherit;">
                <span style="display: block; padding: 5px;">
                  <v-icon icon="fal fa-user-graduate" class="text-xl" />
                </span>
                요청서찾기
              </MiscDYLink>
            </li>
            <li style="list-style: none; width: 25%; float: left;">
              <MiscDYLink
                :href="siteName === '레슨올' ? 'https://www.lessonall.com/mer/milk/messageBox?perPage=5&mode=all&checkOpenMessage=true' : ''"
                :to="siteName === '레슨올' ? '' : '/mypage/messageBox'" style="color: inherit;">
                <span style="display: block; padding: 5px;">
                  <v-icon icon="fal fa-comments" class="text-xl" />
                </span>
                메시지함
              </MiscDYLink>
            </li>
          </template>

          <template v-else>
            <li style="list-style: none; width: 25%; float: left;">
              <MiscDYLink to="/board/board_notice" style="color: inherit;">
                <span style="display: block; padding: 5px;">
                  <v-icon icon="fal fa-bullhorn" class="text-xl" />
                </span>
                공지사항
              </MiscDYLink>
            </li>
            <li style="list-style: none; width: 25%; float: left;">
              <MiscDYLink to="/board/board_tutoring" style="color: inherit;">
                <span style="display: block; padding: 5px;">
                  <v-icon icon="fal fa-comments" class="text-xl" />
                </span>
                튜터링
              </MiscDYLink>
            </li>
            <li style="list-style: none; width: 25%; float: left;">
              <MiscDYLink to="/board/board_chulchk" style="color: inherit;">
                <span style="display: block; padding: 5px;">
                  <v-icon icon="fal fa-check-square" class="text-xl" />
                </span>
                출석체크
              </MiscDYLink>
            </li>
          </template>

          <li style="list-style: none; width: 25%; float: left;">
            <MiscDYLink :href="siteName === '레슨올' ? 'https://www.lessonall.com/mer/mypage/dashboard' : ''"
              :to="siteName === '레슨올' ? '' : '/mypage'" style="color: inherit;">
              <span style="display: block; padding: 5px;">
                <v-icon icon="fal fa-user-circle" class="text-xl" />
              </span>
              마이페이지
            </MiscDYLink>
          </li>
        </ul>
        <div class="clearfix"></div>
      </div>

      <div class="info_bar">
        <span class="helv" style="padding: 6px 0 0 0px;display:block;">
          <MiscDYLink to="/customerCenter/agree" style="color: inherit;">[ 이용약관 ]</MiscDYLink> / <MiscDYLink
            to="/customerCenter/privacy" style="color: inherit;">[ 개인정보 처리 방침 ]</MiscDYLink>
        </span>
        <span class="helv" style="padding: 6px 0 0 0px;display:block;"><span id="footer_ceo">(주)도약 &nbsp;&nbsp;&nbsp;대표자 :
            심병욱,심병민</span> &nbsp;&nbsp;/&nbsp;&nbsp;<span id="footer_companyNumber">사업자등록번호 : 130-86-80648</span></span>
        <span class="helv" style="padding: 6px 0 0 0px;display:block;"><span>고객센터 : {{ ADMIN_PHONE_NUMBER }}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span id="footer_businessNumber">통신판매업신고 : 제2013-경기부천-0917호</span></span>
        <span class="helv" style="padding: 6px 0 0 0px;display:block;">주소 : 경기도 부천시 상동 534-5 현해프라자 404,405 (주)도약</span>
      </div>

      <div class="end_bar">
        <span class="copyrights pull-left">COPYRIGHT© DOYAC. ALL RIGHTS RESERVED.</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBullhorn, faCheckSquare, faComments, faUserCircle, faUserGraduate, faUsersClass } from '@fortawesome/pro-light-svg-icons';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';

library.add(faBullhorn, faComments, faCheckSquare, faUserCircle, faUsersClass, faUserGraduate)

const router = useRouter()
const route = useRoute()
const windowStore = useDYWindowStore()
const { siteName, ADMIN_PHONE_NUMBER, CUSTOMER_EMAIL_ADDRESS, CUSTOMER_FAX_NUMBER, fullDomain } = useAppConfig()

const getWindow = () => process.client ? window : undefined

</script>

<style scoped>
.page-footer {
  height: auto;
}

.page-footer {
  height: auto;
  color: #CCCCCC;
  font-size: 10px;
  text-align: center;
  padding: 10px;
}

.end_bar {
  border-top: 1px solid #5C5C5C;
  margin-top: 10px;
  padding-top: 5px;
  height: 25px;
  color: #CCCCCC;
}

em {
  font-style: normal
}

@media only screen and (min-width:1100px) {

  /*screen의 width가 768px이하일때*/
  /*.main_layer_banner {display:none;} */
  .main_wrap_box {
    min-width: 1100px;
  }
}

.main_wrap_box {
  min-width: 980px;
}

#footer_box .link_zone {
  background: #e7e7e7;
  padding: 44px 0;
  border-bottom: 1px solid #c6c6c6;
}

#footer_box .link_zone>div {
  border-left: 1px solid #c6c6c6;
  padding-bottom: 10px;
}

#footer_box .link_zone dl {
  float: left;
  padding: 0 8px 0 10px;
  width: 144px;
  height: 190px;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #c6c6c6;
}

#footer_box .link_zone dl:first-child {
  padding: 0 12px 0 20px;
  width: 90px;
}

#footer_box .link_zone dl.last {
  border-right: 0;
}

#footer_box .link_zone dl * {
  display: block;
}

#footer_box .link_zone dl dt {
  float: none;
  font-size: 13px;
  margin-bottom: 14px;
}

#footer_box .link_zone dl dt a {
  color: #d01715;
}

#footer_box .link_zone dl dd a {
  font-size: 12px;
  color: #878787;
}

#footer_box .link_zone dl dd li {
  float: none;
  padding-bottom: 5px;
}

#footer_box .address {
  padding-left: 20px;
}

#footer_box .address * {
  color: #878787;
}

#footer_box .address .info {
  overflow: hidden;
}

#footer_box .address .info li {
  padding-left: 9px;
  margin-left: 9px;
  float: left;
  border-left: 1px solid #383838;
}

#footer_box .address .info li:first-child {
  padding-left: 0;
  margin-left: 0;
  border-left: 0;
}

#footer_box .address .info li a {
  font-weight: bold;
  color: #383838;
  line-height: 14px;
  font-size: 11px;
}


/*footer정렬*/


#image_list_1 ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 10pt;
}

.doyac_footer .footer_cs {
  position: absolute;
  float: right;
  top: 2px;
  right: 0;
  width: auto;
}

.doyac_footer .footer_cs p {
  /*font-family: Dotum;*/
  margin-bottom: 7px;
  text-align: right;
}

.doyac_footer .footer_cs p span {
  /*font-family: Dotum;*/
  font-size: 12px;
  color: #999;
}

.doyac_footer .footer_cs .tel {
  font-size: 20px;
  vertical-align: middle;
  line-height: 25px;
}

.doyac_footer .footer_cs .tel span {
  vertical-align: middle;
  font-size: 20px;
  font-weight: normal;
}

.doyac_footer .footer_cs .tel span strong {
  font-weight: bold;
}

.doyac_footer .footer_cs .tel .btn {
  font-size: 11px;
  color: #ccc;
  display: inline-block;
  width: 105px;
  height: 25px;
  line-height: 12px;
  text-align: center;
  background: #666;
  margin-left: 5px;
  font-weight: bold;
}

/* .doyac_footer .footer_mark {
  position: absolute;
  right: 0;
  bottom: 0;
} */

.doyac_footer .footer_mark ul {
  font-size: 0;
}

.doyac_footer .footer_mark ul:after {
  content: '';
  clear: both;
  display: block;
}

.doyac_footer .footer_mark ul li {
  float: left;
  width: auto;
  margin-left: 8px;
}

.doyac_footer .footer_mark ul li img {
  display: inline-block;
  vertical-align: middle;
}

.doyac_footer .footer_mark ul li p {
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  /*font-family: Dotum;*/
  color: #999;
  margin-left: 8px;
  text-align: left;
}

.doyac_footer .footer_add {
  float: left;
  width: 700px;
  height: 120px;
  padding-left: 270px;
  color: #bebebe;
  font-size: 11px;
  text-align: left;
  background: url("/images/footer_bg_am2.png") no-repeat 70px;
}

.doyac_footer .footer_add a {
  font-size: 12px;
}

.doyac_footer .footer_add p {
  margin: 15px 0 0;
}

.doyac_footer .footer_add p:first-child {
  margin: 0px
}

.doyac_footer .footer_add p:first-child a:first-child {
  margin-left: 0;
}

.doyac_footer .footer_add .address {
  font-size: 11px;
  line-height: 18px;
}

.doyac_footer .footer_add p a strong {
  color: #ff6600;
}

.doyac_footer .footer_cs {
  float: right;
  text-align: left;
  color: #bebebe;
  font-size: 11px;
}

.doyac_footer .footer_cs span {
  font-size: 14px;
  font-weight: bolder;
  color: #d0d0d0;
}

.doyac_trade {
  background: #5d5d5d;
  position: relative;
  width: 100%;
  min-width: 1100px;
  overflow: hidden;
  padding: 15px 0;
  text-align: center;
}

.doyac_trade p {
  color: #bebdbd;
}

.main_sitemap {
  position: relative;
  width: 100%;
  min-width: 1100px;
  background: #4a4a4a;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 45px;
}

.main_sitemap .sitelist {
  background: url("/images/sitemap_bg.png") repeat-y left top;
  padding-left: 13px;
  text-align: left;
  width: 120px;
  min-height: 210px;
  float: left;
}

.main_sitemap .sitelist dt {
  padding: 15px 0;
}

.main_sitemap .sitelist dt a {
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}

.main_sitemap .sitelist dd {}

.main_sitemap .sitelist dd li,
.main_sitemap .sitelist dd li a {
  color: #ccc;
  line-height: 23px;
  font-size: 12px;
}

.main_sitemap .sitelist dd li a:hover {
  text-decoration: underline;
}

.doyac_footer {
  background: #27343c;
  position: relative;
}

.doyac_footer .footer_add {
  padding-left: 160px;
  height: auto;
}

.doyac_footer .footer_add * {
  font-size: 12px;
  color: #999;
}

.doyac_footer .footer_add p {
  margin-top: 0;
  line-height: 25px !important;
}

.doyac_footer .footer_add p strong {
  color: #fff;
}

.doyac_footer .footer_add .address {
  font-size: 12px;
}

.doyac_trade {
  background: #27343c;
  border-bottom: 1px solid #3a444a;
  height: 70px;
  line-height: 70px;
  padding: 0;
}


.main_sitemap {
  border-bottom: 1px solid #3a444a;
  background: #27343c;
  margin-bottom: 25px;
}


.main_sitemap .sitelist {
  float: none;
  background: none;
  border-left: 1px solid #3a444a;
  height: 300px;
  width: auto;
  display: table-cell;
  min-width: 90px;
  padding-left: 15px;
  padding-bottom: 25px;
}

.main_sitemap .sitelist:first-child {
  border-left: none;
}

.main_sitemap .sitelist dt {
  padding-top: 25px;
  padding-bottom: 18px;
}

.main_sitemap .sitelist dt a {
  color: #12aaf0;
  font-size: 16px;
}

.main_sitemap .sitelist dd li,
.main_sitemap .sitelist dd li a {
  color: #999;
}

.doyac_lump_footer {
  position: relative;
  height: 250px;
  width: 1100px;
  margin: 0 auto;
}

.doyac_lump_footer>p:first-child {
  color: #525d63;
  text-align: left;
  font-size: 12px;
  margin-bottom: 23px;
  float: left;
}

.doyac_lump_footer>p:first-child>a {
  margin: 0 30px;
  color: #999;
  /*font-family: Dotum;*/
  font-weight: bold;
}

.doyac_lump_footer>p:first-child>a strong {
  /*font-family: Dotum;*/
}

.doyac_lump_footer>p:first-child>a:first-child {
  margin-left: 0;
}
</style>