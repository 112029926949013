<template>
  <div v-if="isVisible" TopMenuSwipe="" class="mt-1 hiddenPrintArea" id="topMenuSwipe"
    style="border-bottom:1px solid #fff;">
    <div v-if="!slideBeggin" class="left-swipe-btn swipe-btn"
      style="background-color: red; height: 40px; width: 10px; position: absolute; left: 0px; z-index: 2;">
      <div class="flex" style="justify-content:center; align-items:center; height: 100%;">
        <v-icon icon="fal fa-chevron-left" class="bounceUpAndDown_short" style="color:white;"></v-icon>
      </div>
    </div>
    <div v-if="!slideEnd" class="right-swipe-btn swipe-btn"
      style="background-color: red; height: 40px; width: 10px; position: absolute; right: 0px; z-index: 2;">
      <!-- 이 오버플로우 X 꼭 있어야 함. 안 그러면 앱 등에서 터치시 좌우로 흔들림 -->
      <div class="flex overflow-x-hidden" style="justify-content:center; align-items:center; height: 100%;">
        <v-icon icon="fal fa-chevron-right" class="bounceUpAndDown_short" style="color:white;"></v-icon>
      </div>
    </div>

    <ClientOnly>
      <!-- 반드시 최대 설정 메뉴 길이 이상은 잡지 못하도록. 이게 없다면 화면을 늘렸을 때 빈 공간이 생겨버림 -->
      <nav v-if="menuTemplate">
        <swiper-container id="swiper-menu-top" ref="swiper" :centered-slides-bounds="true"
          :slides-per-view="menuTemplate && menuTemplate.length < slidePerView ? menuTemplate.length : slidePerView"
          :space-between="0" :slide-to-clicked-slide="true" style="background-color: rgb(255, 255, 255);">
          <!-- <template > -->
          <swiper-slide v-for="(item, key) in menuTemplate" :key="key" class="swiper-slide"
            :class="checkSelectedMenu(item)">
            <MiscDYLink :to="item.routerLink ? item.href : ''" :href="item.routerLink ? '' : item.href"
              class="w-full h-full flex items-center justify-center"
              :class="(!userStore.isSignedIn && item.text === '회원가입') || (userStore.isSignedIn && item.text === '전문가찾기') || item.text === '정규강의' ? 'char bounceUpAndDown_short' : ''"
              v-html="item.text"></MiscDYLink>
          </swiper-slide>
          <!-- </template> -->
        </swiper-container>
      </nav>
    </ClientOnly>

  </div>
</template>

<script setup lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import 'swiper/css';
import { register } from 'swiper/element';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { useDYLayoutStore } from '~/modules/nuxt3-module-doyac/stores/layout';
import { useDYMainStore } from '~/modules/nuxt3-module-doyac/stores/main';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';

library.add(faChevronLeft, faChevronRight)

const route = useRoute()
const windowStore = useDYWindowStore()
const userStore = useDYUserStore()
const { shortCutJson } = useDYMainStore()
const { siteName, dbDomain } = useDYAppConfig()
const layoutStore = useDYLayoutStore()

register() //웹 컴포넌트 동작시 필수

// -------------------------인터페이스

// -------------------------데이터

const swiper = ref<any>()
const slideBeggin = ref(true)
const slideEnd = ref(false)

// -------------------------컴퓨티드

const isVisible = computed(() => {
  return !layoutStore.isActionBarTransparent && layoutStore.isTopMenuLineVisible
})

const slidePerView = computed(() => {
  let perView = 8

  if (windowStore.windowWidth < 577) {
    perView = 5
  } else if (windowStore.windowWidth < 768) {
    perView = 6
  } else if (windowStore.windowWidth < 992) {
    perView = 7
  } else if (windowStore.windowWidth < 1200) {
    perView = 8
  }

  return perView
})

const menuTemplate = computed(() => {
  let menu

  if (siteName === '도약닷컴') {
    menu = [
      { text: '정규강의', href: '/courses', routerLink: true, order: 0 },
      { text: '곡별강의', href: '/courses/mini', routerLink: true, order: 1 },
      { text: '튜터링', href: '/tutoring', routerLink: true, order: 2 },
      { text: '악보/MR', href: '/akboMr', routerLink: true, order: 3 },
      { text: '자격증', href: '/license', routerLink: true, order: 4 },
      { text: '북스토어', href: '/book', routerLink: true, order: 5 },
      { text: '이벤트', href: '/board/board_eventinfo', routerLink: true, order: 6 },
      { text: '고객센터', href: '/customerCenter', routerLink: true, order: 7 }
    ]
  } else if (siteName === '도약아트') {
    menu = [
      { text: '정규강의', href: '/courses', routerLink: true, order: 0 },
      { text: '작품별강의', href: '/courses/mini', routerLink: true, order: 1 },
      { text: '도약화방', href: '/shop', routerLink: true, order: 2 },
      { text: '자격증', href: '/license', routerLink: true, order: 3 },
      { text: '튜터링', href: '/tutoring', routerLink: true, order: 4 },
      { text: '이벤트', href: '/board/board_eventinfo', routerLink: true, order: 5 },
      { text: '고객센터', href: '/customerCenter', routerLink: true, order: 6 }
    ]
  } else if (siteName === '마이퍼스트가이드') {
    menu = [
      { text: "<span class='flag flag-fr' style='margin-right:3px;'></span>프랑스", href: '/nations/FR', routerLink: true, order: 0 },
      { text: "<span class='flag flag-it' style='margin-right:3px;'></span>이탈리아", href: '/nations/IT', routerLink: true, order: 1 },
      { text: "<span class='flag flag-gb' style='margin-right:3px;'></span>영국", href: '/nations/GB', routerLink: true, order: 2 },
      { text: "<span class='flag flag-es' style='margin-right:3px;'></span>스페인", href: '/nations/ES', routerLink: true, order: 3 },
      { text: "<span class='flag flag-jp' style='margin-right:3px;'></span>일본", href: '/nations/JP', routerLink: true, order: 4 },
      { text: "<span class='flag flag-kh' style='margin-right:3px;'></span>캄보디아", href: '/nations/KH', routerLink: true, order: 5 },
      { text: "마이페이지", href: '/mypage', routerLink: true, order: 6 }
    ]
  } else if (siteName === '레슨올') {
    menu = [
      { text: '전문가찾기', href: '/requestForm/teacher/list', routerLink: true, order: 0 },
      { text: '학생요청서', href: '/requestForm/student/list', routerLink: true, order: 1 },
      { text: '연습실', href: '/m/practiceroom/practiceroomList_ax.php', routerLink: false, order: 2 },
      // { text: '채용정보', href: '/requestForm/student/list?part=%EC%B1%84%EC%9A%A9', routerLink: true, order: 3 },
      { text: '부가서비스', href: '/mobile_gawe/07course/payment1.php', routerLink: false, order: 4 },
      // { text: '공지사항', href: '/renewal_doyac/Board/boardEntryList.php?boardName=board_notice', routerLink: false, order: 5 },
    ]
    if (!userStore.isSignedIn) { menu.unshift({ text: '회원가입', href: '/join/step1', routerLink: true, order: 9 }) }
  }

  if (menu && (siteName === '도약닷컴' || siteName === '도약아트')) {
    menu.splice(slidePerView.value - 1, 0, { text: '마이클래스', href: '/mypage', routerLink: true, order: 9 })
  }

  if (menu && (siteName === '레슨올')) {
    menu.splice(slidePerView.value - 1, 0, { text: "마이페이지", href: '/mer/mypage/dashboard', routerLink: false, order: 6 })
  }

  return menu
})

const totalTemplateLength = computed(() => {
  if (dbDomain === 'doyacweb') return 9
  if (dbDomain === 'doyacart') return 8
  if (dbDomain === 'myfirstguide') return 7
  if (dbDomain === 'lessonall') return !userStore.isSignedIn ? 7 : 6
})

// -------------------------메서드

const checkSelectedMenu = (item: { text: string, href: string }) => {
  if (route.path.includes('/courses/mini')) { //미니코스인 경우 정규강의에도 같이 불이 들어올 수 밖에 없어서 이렇게 처리함
    return item.text === '곡별강의' || item.text === '작품별강의' ? 'selectedMenu' : ''
  } else if (route.path.includes('/board/board_chulchk') || route.path.includes('/review') || route.path.includes('/board/board_notice') || route.path.includes('/board/board_eventinfo')) {
    return item.text === '커뮤니티' ? 'selectedMenu' : ''
  } else if (!route.path.includes('/board/board_blog') && (route.path.includes('/board/board_tutoring') || route.path.includes('/board/board_'))) {
    return item.text === '튜터링/교육질문' ? 'selectedMenu' : ''
  } else if (route.path.includes('/payment/akboMrPayment')) {
    return item.text === '악보/MR' ? 'selectedMenu' : ''
  } else if (route.path.includes('/cs/service')) {
    return item.text === '마이클래스' ? 'selectedMenu' : ''
  } else {
    return route.path.includes(item.href) ? 'selectedMenu' : ''
  }
}

const topMenuShow = () => {
  let perView

  if (windowStore.windowWidth < 577) {
    perView = siteName === '레슨올' ? 4 : 5
  } else if (windowStore.windowWidth < 768) {
    perView = siteName === '레슨올' ? 5 : 6
  } else if (windowStore.windowWidth < 992) {
    perView = siteName === '레슨올' ? 6 : 7
  } else if (windowStore.windowWidth < 1200) {
    perView = siteName === '레슨올' ? 7 : 8
  } else { perView = 5 }

  // 반드시 최대 설정 메뉴 길이 이상은 잡지 못하도록. 이게 없다면 화면을 늘렸을 때 빈 공간이 생겨버림
  return (totalTemplateLength.value && totalTemplateLength.value < perView) ? totalTemplateLength.value : perView
}

const currentUrl = () => {
  const href = route.fullPath

  if (href === '/') { return undefined } // 메인페이지로 왔을 때
  // 닷컴 기반
  if (href.includes('courses') && !href.includes('mini')) return 0 // 넉스트용 정규강의
  if (href.includes('mini')) return 1
  if (href.includes('book') || href.includes('shop')) return 2
  if (href.includes('akboMr')) return 3
  if (href.includes('board_eventinfo')) {
    if (dbDomain === 'doyacart') return topMenuShow() >= 7 ? 4 : 5
    return topMenuShow() - 1 >= 4 ? 6 : 5
  }
  if (href.includes('license')) {
    if (dbDomain === 'doyacart') { return topMenuShow() >= 5 ? 3 : 4 }

    // 이 식의 의미는 원래 화상레슨 메뉴는 5번째 위치해있고 이것이 스와이프 인덱스로 따지면 4가 된다. 그렇다는 것은 한 화면에 나올 수 있는 개수가 총 6개여야(+마이클래스 메뉴가 추가되므로) 4번 인덱스 그 외엔 5번인덱스가 된다
    return topMenuShow() >= 6 ? 4 : 5
  }
  // if (href.includes('videoLesson')) {
  //   if (dbDomain === 'doyacart') { return topMenuShow() >= 6 ? 4 : 5 }
  //   return topMenuShow() >= 7 ? 5 : 6
  // }

  if (href.includes('/mypage')) { return topMenuShow() - 1 }

  if (href.includes('customerCenter') || href.includes('board_qna') || href.includes('board_faq') || href.includes('board_notice') || href.includes('board_notice')) {
    return totalTemplateLength.value! - 1
  }

  return undefined
}

const selectingMenuEnterPage = () => {
  //오더값을 베이스로 조건에 따라 (윈도우 사이즈와, 마이클래스 유무 등) 변수를 계산해서 현재 라우트에 맞는 슬라이더로 이동시킬 예정 
  const moveTopSwiper = currentUrl() // 현재 페이지에 해당하는 탑메뉴의 순번을 감지
  if (route.path === '/' && swiper.value) { // 메인페이지인경우
    swiper.value.swiper.slideTo(0)
  }
  if (moveTopSwiper !== undefined && swiper.value && route.path !== '/') { // 현재 화면에 보여줄 수 있는 내용보다 뒷 메뉴의 라우트 주소인 경우 슬라이드 효과로 이동
    swiper.value.swiper.slideTo(moveTopSwiper)
  }
}

if (process.client) {
  watch(() => route.path, (v) => { //여길 타는 것 자체가 라우트가 이동되었다는 뜻이므로
    selectingMenuEnterPage()
  })
}

// ------------------------- 훅

</script>

<style lang="scss" scoped>
@import '/modules/nuxt3-module-doyac/assets/scss/animation.scss';

[TopMenuSwipe] {
  background-color: #3d3e47;
}

[TopMenuSwipe] #swiper-menu-top {
  width: 100%;
  background-color: #3d3e47;
  box-shadow: 0 1px rgb(0 0 0 / 10%);
  // border-bottom: 1px solid #f9f9f9;
}

@media only screen and (max-width: 340px) {
  [TopMenuSwipe] #swiper-menu-top .swipe-btn {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  [TopMenuSwipe] #swiper-menu-top {
    width: 100%;
    padding-right: 10px;
  }

  [TopMenuSwipe] #swiper-menu-top.myfirstguide {
    width: 100%;
    padding-right: 0px;
  }
}

[TopMenuSwipe] .swiper-container {
  width: 100%;
  height: 100%;
  overflow: visible !important;
}

.swiper-slide {
  text-align: center;
  background: #ffffff;
  height: 40px;
  font-weight: 600;
  border-bottom: 3px solid #ffffff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide:hover {
  color: #e36082;
}

.selectedMenu {
  color: #e36082;
  border-bottom: 3px solid #e36082;
}

#swiper-menu-top .top_slide_menu.myfirstguide:not(:last-child) {
  background-image: linear-gradient(#e3e3e3 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 7px;
  background-repeat: repeat-y;
}

@media screen and (max-width: 347px) {
  #swiper-menu-top .top_slide_menu.myfirstguide {
    display: flex;
    flex-direction: column;
  }
}

.char {
  text-emphasis-style: dot;
  text-emphasis-position: over left;
  -webkit-text-emphasis-style: dot;
  -webkit-text-emphasis-position: over;
  text-emphasis-color: #e36082;
  padding-bottom: 10px;
}

#swiper-menu-top a {
  font-size: 13px;
}

@media screen and (min-width: 768px) {
  #swiper-menu-top a {
    font-size: 14px;
  }
}</style>