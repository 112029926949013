<template>
  <div>

    <!-- <v-skeleton-loader v-if="loading" class="mx-auto" type="table-heading, list-item-two-line" :min-height="300">
      <DYLoadingComponent />
      로딩 컴포넌트 필요
    </v-skeleton-loader> -->

    <div id="mainPageTopLine" style="position: relative;z-index:101;">
      <span id="HntTopStart" style="display:none"></span>
      <div id="wrap">
        <div id="header_section">
          <div id="header" class="header" style="padding: 0;height: 31px;" :style="{backgroundColor: isAdmin ? '#fff8e9' : ''}">
            <div style="margin:0 auto;max-width:1100px;width:100%;">
              <div class="tnb" style="height:31px;">
                <ul id="tnb_hana" class="tnb_hana">
                  <slot>
                    <!-- 각 사이트별 컨텐츠 필요. 인강에서 호출하는 경우 IGDefault에서 전용 코드 부르면 됨 -->
                  </slot>
                </ul>
                <div class="tnb_mypage">
                  <div v-if="isAdmin" class="inline-block ml-2">
                    <p class=" text-base">
                      <MiscDYLink :href="fullDomain+'/apple'" target="_blank" class="bg-blue">관리자 로그인 중..</MiscDYLink>
                    </p>
                  </div>
                  <div v-else-if="userStore.isCompanyIp" class="inline-block ml-2">
                    <p class=" text-base">
                      <MiscDYLink :href="fullDomain+'/apple'" target="_blank" class="bg-blue">사내접속 중..</MiscDYLink>
                    </p>
                  </div>
                  <div class="app_down">
                    <p class="btn_download text-base">
                      <MiscDYLink :to="'/introduce/app'">앱다운</MiscDYLink>
                    </p>
                  </div>
                  <ul class="tnb_mylink">
                    <li v-if="userStore.isSignedIn" class="li_login loginClassY">
                      <DYLogoutLink v-slot="{ requestLogout }">
                        <a @click.prevent="requestLogout">로그아웃</a>
                      </DYLogoutLink>
                    </li>
                    <li v-else class="li_login loginClassN" style="cursor: pointer;">
                      <DYLoginLink />
                    </li>

                    <li >
                      <MiscDYLink to="/mypage/recentChecked">
                        {{ siteName === '레슨올' ? '최근 본 요청서' : '최근 본 상품' }}
                      </MiscDYLink>
                    </li>

                    <li v-if="!userStore.isSignedIn" class="li_member loginClassN">
                      <MiscDYLink to="/join/step1" style="display: inline-block; padding: 7px 11px 6px;">회원가입</MiscDYLink>
                    </li>

                    <li class="li_mypage">
                      <MiscDYLink :href="siteName === '레슨올' ? '/mer/mypage/dashboard' : ''" :to="siteName === '레슨올' ? '' : '/mypage'">{{ siteName === '도약닷컴' || siteName === '도약아트' ? '마이클래스' : '마이페이지' }}</MiscDYLink>
                      <ul class="mypage_list">
                        <li>
                          <MiscDYLink :href="siteName === '레슨올' ? '/mer/mypage/dashboard' : ''" :to="siteName === '레슨올' ? '' : '/mypage'">{{ siteName === '도약닷컴' || siteName === '도약아트' ? '마이클래스' : '마이페이지' }}</MiscDYLink>
                        </li>
                        <li v-if="siteName !== '레슨올'">
                          <MiscDYLink to="/payment/cart">장바구니</MiscDYLink>
                        </li>
                        <li><MiscDYLink :href="siteName === '레슨올' ? '/mer/milk/join02?modify=y' : ''" :to="siteName === '레슨올' ? '' : '/mypage/checkpw'">정보수정</MiscDYLink></li>
                        <li>
                          <MiscDYLink :href="siteName === '레슨올' ? '/mer/milk/messageBox' : ''" :to="siteName === '레슨올' ? '' : '/mypage/messageBox'">내쪽지함</MiscDYLink>
                        </li>
                      </ul>
                    </li>
                    <li v-if="userStore.isSignedIn && siteName !== '레슨올'" class="li_member loginClassY">
                      <MiscDYLink to="/payment/cart">장바구니</MiscDYLink>
                    </li>
                  </ul>
                  <!-- <p class="tnb_trabiz"><a @click="mainbookmark()" title="즐겨찾기" style="padding: 7px 0 6px 11px;">★ -->
                      <!-- 즐겨찾기</a></p> -->

                  <slot name="top-right-for-pc"></slot>
                </div>
                <div id="hotitem_bg"
                  style="width:161px; height:0; position:relative; right:0; left:180px; top:0; z-index:1;float:left;padding:0; margin:0;line-height:0;">
                  <div id="hotitem_bg2"
                    style="width:161px; height:0; position:absolute; right:-830px; top:157px; z-index:1;float:left;padding:0; margin:0;line-height:0;">
                    <div class="vote_product"
                      style="width:161px; height:0;top: -126px;position: relative;padding:0; margin:0;line-height:0;">
                      <div class="imageSilderExt"
                        style="width:161px; height:0;top:-120px;float:left;padding:0; margin:0;line-height:0;">
                        <div class="jCarouselLite"
                          style="height:131px; visibility: visible; overflow: hidden; z-index: 9; left: 0; top:-11px; float:left;padding:0; margin:0;line-height:0;">
                          <ul style="list-style-type: none; z-index: 1; left: 0;padding:0; margin:0;line-height:0;">
                            <!-- 상단 롤링 나중에 Api로 작업 -->
                            <li class="hidden"
                              style="overflow: hidden; float: left; width: 161px; height: 110px;padding:0; margin:0;line-height:0;">
                              <div class="open-popup">
                                <a :href="goodContentsCertificationImageUrl" data-fancybox="">
                                  <span class="product_thumb">
                                    <img :src="`${fullDomain}/upFolder/doyacart/upFolder/upFile/BANNER/BANNER_59ffd56856afb.png`"
                                      width="161px" height="110px" alt="">
                                  </span>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <button type="button" class="side_btn next"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div><span id="HntTnbEnd" style="display:none"></span>
            </div>
          </div>
          <div id="search_section" class="dark:!bg-[#121212]">
            <div class="search_area" style="height: inherit;">
              <div class="flex" style="height: inherit; width: 100%; align-items: center;">
                <div class="col-4 flex items-baseline">
                  <slot name="left-item-for-pc"></slot>
                </div>
                <div class="col-4">
                  <MiscDYLink to="/" style="display: block;margin:0 auto;" :style="{width: siteName === '도약닷컴' ? '142px' : '180px'}">
                    <img :src="`${fullDomain}/m/images/top_logo.svg`" :style="siteName === '마이퍼스트가이드' ? 'height:37px' : 'height:46px;'"
                      :alt="siteName">
                  </MiscDYLink>
                </div>
                <ul class="neighbor col-4 flex" style="position: relative; top:0; flex-direction: row-reverse;">
                  <slot name="right-item-for-pc">
                    <li class="last-child">
                      <MiscDYLink :to="'/customerCenter'" class="dark:!text-white">고객센터</MiscDYLink>
                    </li>
                    <li v-if="siteName === '레슨올'">
                      <MiscDYLink :href="userStore.isSignedIn ? '/mer/mypage/dashboard' : ''" :to="userStore.isSignedIn ? '' : '/join/step1'" class="dark:!text-white">{{ userStore.isSignedIn ? '내 전문분야/요청서 관리' : '전문가/학생 등록' }}</MiscDYLink>
                    </li>
                    <li v-if="siteName !== '레슨올'">
                      <MiscDYLink to="/board/board_eventinfo" class="dark:!text-white">이벤트</MiscDYLink>
                    </li>
                    <li v-if="siteName !== '레슨올'">
                      <MiscDYLink to="/search" class="searchMenu dark:!text-white" style="text-decoration: none;">
                        <v-icon icon="fal fa-search" style="font-size: 21px;vertical-align: middle;" />
                        <span style="margin-left: 5px;">검색</span>
                      </MiscDYLink>
                    </li>
                  </slot>
                </ul>
              </div>
            </div>
            <!--    검색기능      -->
            <!--          {{getParam("foo")}}-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMagic, faSearch } from '@fortawesome/pro-light-svg-icons';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';
import { useDYAuth } from '~/modules/nuxt3-module-doyac/composables/auth';
library.add(faSearch, faMagic)

const userStore = useDYUserStore()
const { siteName, fullDomain } = useDYAppConfig()
const { isAdmin } = useDYAuth()

const loading = ref(true)

const goodContentsCertificationImageUrl = computed(() => {
  switch (siteName) {
    case '도약닷컴': return '/renewal/img/contents_doyac.png'
    case '도약아트': return '/renewal/img/contents_art.png'
    case '마이퍼스트가이드': return '/renewal/img/20191023-contents_myfirtsguide.jpg'
    case '레슨올': return '/renewal/img/footer/footger_contents.png'
    default: return undefined
  }
})

// const mainbookmark = () => {
//   try {
//     //@ts-ignore
//     window.external.AddFavorite("https://www.doyacart.com", "[도약아트] 미술 동영상강의의 최강자!");
//   } catch (e) {
//     alert("이 버젼의 브라우저에서는 지원하지 않습니다.");
//   }
// }

onMounted(() => {
  loading.value = false
})

  // methods:{
    // emphasizeMainTab(){
    //   $("#tnb_hana").find("li").removeClass("first-child");
    //   $("#tnb_hana_child").addClass("first-child");
    // },
  // },
</script>


<style scoped lang="scss">
/* head 레이아웃을 포함한 tnb 영역 */
.header #skipnav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 40;
}

.header #skipnav a {
  position: absolute;
  top: -9999px;
  font-size: 14px;
  color: #555;
}

.header #skipnav a:hover,
#skipnav a:active,
#skipnav a:focus {
  display: inline-block;
  top: 0;
  width: 1200px;
  height: 13px;
  padding: 5px 0;
  margin: 0 auto;
  border: 3px solid #ccc;
  font-weight: bold;
  background-color: #FFF;
  color: #000;
  text-align: center;
}

.header #accessibility_info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 40;
}

.header #accessibility_info a {
  position: absolute;
  top: -9999px;
  font-size: 14px;
  color: #555;
}

.header #accessibility_info a:hover,
#accessibility a:active,
#accessibility a:focus {
  display: inline-block;
  top: 0;
  width: 1200px;
  height: 13px;
  paddinghana: 5px 0;
  margin: 0 auto;
  border: 3px solid #ccc;
  font-weight: bold;
  background-color: #FFF;
  color: #000;
  text-align: center;
}

/* header 108 */
#header_section {
  position: relative;
  z-index: 100;
  /*height: 64px; margin-bottom: 44px; border-bottom: 0px solid #DEE0E1; */
  border-top: 1px solid #D3E1E1;
  text-align: left
}

#header_section .header {
  position: relative;
  z-index: 101;
  /* height: 108px;*/
  margin: 0 auto;
  border-bottom: 1px solid #d1d1d1;
  background-color: #f2f2f2;
  box-sizing: content-box;
}

.header .tnb {
  zoom: 1;
  height: 31px;
  width: 100%;
}

.header .tnb:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  width: 0;
  height: 0
}

.header .tnb a {
  color: #444
}

.header .tnb .tnb_hana {
  overflow: hidden;
  float: left;
}

.header .tnb .tnb_hana li {
  float: left;
  border-right: 1px solid #D1D1D1;
}

.header .tnb .tnb_hana li:first-child {
  border-left: 1px solid #D1D1D1;
}

.header .tnb .tnb_hana li a {
  display: block;
  width: 98px;
  height: 18px;
  padding: 7px 0 6px;
  border-bottom: 1px solid #D1D1D1;
  font-size: 13px;
  font-weight: 600;
  background-color: #F2F2F2;
  background-image: none;
  text-align: center;
  color: #444;
  line-height: 18px;
  box-sizing: content-box;
}

.header .tnb .tnb_hana li.first-child a,
.header .tnb .tnb_hana li a:hover {
  border-bottom: 1px solid #FFF;
  background-color: #FFF;
  color: #c83d67;
  text-decoration: none
}

/* Tnb 오른쪽 영역 */
.header .tnb .tnb_mypage {
  zoom: 1;
  float: right
}

.header .tnb .tnb_mypage:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  width: 0;
  height: 0
}

.header .tnb .tnb_mypage a {
  display: inline-block;
  padding: 7px 11px 6px;
  font-size: 12px;
  color: #848484;
  line-height: 18px;
}

.header .tnb .tnb_mypage a:hover {
  text-decoration: none;
  color: #c83d67
}

.header .tnb .tnb_mylink {
  zoom: 1;
  float: left;
}

.header .tnb .tnb_mylink:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  width: 0;
  height: 0
}

.header .tnb .tnb_mylink ul {
  zoom: 1;
  float: left;
}

.header .tnb .tnb_mylink ul:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  width: 0;
  height: 0
}

.header .tnb .tnb_mylink li {
  float: left;
  background-image: none
}

.header .tnb .tnb_mylink li.li_login a {
  padding: 7px 11px 6px 18px
}

.header .tnb .tnb_mypage .app_down {
  float: left;
  padding-right: 18px;
  background: url('https://www.doyac.com/renewal_doyac/img/img_short_bar2.gif') no-repeat right 11px
}

.header .tnb .tnb_mypage .app_down a {
  display: inline-block;
  padding: 7px 0 7px 20px;
  font-size: 12px;
  background: url('https://www.doyac.com/renewal_doyac/img/icon_set_ht.png') no-repeat 10px 10px;
  color: #c83d67;
  line-height: 18px
}

.header .tnb .tnb_mylink li.li_mypage {
  z-index: 1000;
  position: relative;
}

.header .tnb .tnb_mylink li.li_mypage:hover a {
  color: #c83d67
}

.header .tnb .tnb_mylink li ul li a {
  color: #757575 !important
}

.header .tnb .tnb_mylink li ul li a:hover {
  color: #444 !important
}

.header .tnb .tnb_mylink li.li_mypage a {
  z-index: 10000;
  padding: 7px 11px 6px 4px;
  background: url('https://www.doyac.com/renewal_doyac/img/icon_set_ht.png') no-repeat 59px -329px;
  position: relative;
  cursor: pointer
}

.header .tnb .tnb_mylink li.li_mypage a:hover {
  background-position: 59px -669px;
}

.header .tnb .tnb_mylink li.li_mypage:hover .mypage_list {
  display: block
}

.header .tnb .tnb_mylink li.li_mypage:hover a,
.header .tnb .tnb_mylink li.li_mypage.over a {
  background-position: 59px -669px;
}

.header .tnb .tnb_mylink li.li_mypage .mypage_list {
  z-index: 1000;
  display: none;
  position: absolute;
  left: -11px;
  top: 31px;
  width: 90px;
  border-left: 1px solid #5E5E5E;
  border-right: 1px solid #5E5E5E;
  border-bottom: 1px solid #5E5E5E;
}

.header .tnb .tnb_mylink li.li_mypage .mypage_list li:first-child {
  border-top: 1px solid #d1d1d1;
  margin-top: 0px
}

.header .tnb .tnb_mylink li.li_mypage .mypage_list li {
  float: none;
  background-image: none;
  background-color: #FFF;
}

.header .tnb .tnb_mylink li.li_mypage .mypage_list a {
  display: block;
  padding: 0 0 0 10px;
  background: #FFF;
  text-align: left;
  line-height: 24px;
}

.header .tnb .tnb_mylink li.li_mypage .mypage_list a:hover {
  text-decoration: none;
  background-color: #F0F0F0;
}

.header .tnb .tnb_mylink li.li_customer {
  z-index: 1000;
  position: relative;
  padding-right: 11px
}

.header .tnb .tnb_mylink li.li_customer a {
  z-index: 10000;
  background: url('https://www.doyac.com/renewal_doyac/img/icon_set_ht.png') no-repeat 51px -329px;
  position: relative;
  cursor: pointer;
}

.header .tnb .tnb_mylink li.li_customer a:hover {
  background-position: 51px -669px;
}

.header .tnb .tnb_mylink li.li_customer:hover .customer_list {
  display: block
}

.header .tnb .tnb_mylink li.li_customer:hover a {
  color: #c83d67
}

.header .tnb .tnb_mylink li.li_customer:hover .customer_list a {
  color: #444
}

.header .tnb .tnb_mylink li.li_customer:hover a,
.header .tnb .tnb_mylink li.li_customer.over a {
  background-position: 51px -669px;
}

.header .tnb .tnb_mylink li.li_customer .customer_list {
  z-index: 1000;
  display: none;
  position: absolute;
  left: -11px;
  top: 31px;
  width: 118px;
  border-left: 1px solid #5E5E5E;
  border-right: 1px solid #5E5E5E;
  border-bottom: 1px solid #5E5E5E;
}

.header .tnb .tnb_mylink li.li_customer .customer_list li:first-child {
  border-top: 1px solid #d1d1d1;
  margin-top: 0px
}

.header .tnb .tnb_mylink li.li_customer .customer_list li {
  float: none;
  background-image: none;
  background-color: #FFF;
}

.header .tnb .tnb_mylink li.li_customer .customer_list a {
  display: block;
  padding: 0 0 0 20px;
  background: #FFF;
  text-align: left;
  line-height: 24px;
}

.header .tnb .tnb_mylink li.li_customer .customer_list a:hover {
  text-decoration: none;
  background-color: #F0F0F0;
}

.header .tnb .tnb_mylink li.li_club {
  z-index: 1000;
  position: relative;
  padding-right: 18px;
  background: url('https://www.doyac.com/renewal_doyac/img/img_short_bar2.gif') no-repeat right 11px
}

.header .tnb .tnb_mylink li.li_club a {
  padding: 7px 11px 6px 4px;
  margin-right: 15px;
  z-index: 10000;
  display: inline-block;
  position: relative;
  background: url('https://www.doyac.com/renewal_doyac/img/icon_set_ht.png') no-repeat 82px -329px;
  line-height: 18px;
}

.header .tnb .tnb_mylink li.li_club:hover .club_list {
  display: block
}

.header .tnb .tnb_mylink li.li_club:hover a {
  color: #c83d67
}

.header .tnb .tnb_mylink li.li_club:hover .club_list a {
  color: #444
}

.header .tnb .tnb_mylink li.li_club:hover a,
.header .tnb .tnb_mylink li.li_club.over a {
  background-position: 82px -669px;
}

.header .tnb .tnb_mylink li.li_club .club_list {
  z-index: 1000;
  display: none;
  position: absolute;
  left: 0px;
  top: 31px;
  width: 100px;
  border-left: 1px solid #5E5E5E;
  border-right: 1px solid #5E5E5E;
  border-bottom: 1px solid #5E5E5E
}

.header .tnb .tnb_mylink li.li_club .club_list li:first-child {
  border-top: 1px solid #d1d1d1;
  margin-top: 0px
}

.header .tnb .tnb_mylink li.li_club .club_list li {
  float: none;
  background-image: none;
  background-color: #FFF;
  line-height: 24px
}

.header .tnb .tnb_mylink li.li_club .club_list a {
  display: block;
  padding: 0 0 0 10px;
  width: 98px;
  background: #FFF;
  text-align: left;
  line-height: 24px
}

.header .tnb .tnb_mylink li.li_club .club_list a:hover {
  text-decoration: none;
  background-color: #F0F0F0;
}

.header .tnb .tnb_trabiz {
  float: left;
}

#header_section {
  width: 100%;
  border-bottom: 1px solid #DEE0E1;
}

/*범용닷컴 로고영역 */
#header_section #Franchise {
  position: absolute;
  left: 0;
  top: 0;
  width: 250px;
  height: 45px;
}

#header_section #Franchise h1 {
  top: 16px;
  width: 150px;
}

#header_section #Franchise div {
  position: absolute;
  left: 43px;
  top: 0
}

#header_section #Franchise .FranchiseName {
  position: absolute;
  left: 0;
  top: 48px;
}

.vote_product button {
  border: 0 none;
  background: none;
  cursor: pointer;
}

.vote_product:hover .side_btn {
  display: block;
  position: absolute;
  top: 100px;
  width: 29px;
  height: 39px;
  background: url('https://www.doyac.com/renewal_doyac/img/btn_set.png') no-repeat
}

.vote_product .side_btn {
  display: none;
}

.vote_product .side_btn.prev {
  left: 0;
  background-position: -349px 0;
  z-index: 10;
}

.vote_product .side_btn.prev:hover {
  background-position: -349px -80px;
}

.vote_product .side_btn.next {
  top: 33px;
  right: -28px;
  background-position: -471px 0;
  z-index: 10;
}

.vote_product .side_btn.next:hover {
  right: -28px;
  background-position: -471px -80px;
}

.vote_product .btn_list {
  position: absolute;
  top: 304px;
  left: 52px;
  width: 200px;
  text-align: center;
  z-index: 10000;
}

.vote_product .btn_list a {
  display: inline-block;
  width: 20px;
  height: 25px;
  background: url('https://www.doyac.com/renewal_doyac/img/btn_set.png') no-repeat 0 -87px;
}

.vote_product .btn_list a.on {
  background-position: -21px -87px;
}

#teacher_navigation {
  zoom: 1;
  display: none;
  position: absolute;
  left: 0;
  top: 42px;
  width: 1100px;
  border: 1px solid #D5D5D5;
  border-right: 1px solid #8F949C;
}

#teacher_navigation.on {
  display: block;
}

#gnbSubMenu .mainSubMenu {
  width: 1100px;
}

#gnbMainProduct .msmFdivi {
  height: 410px;
  width: 180px;
}

#gnbArea #gnbSubMenu ul li a {
  padding-left: 6px;
  height: 15px;
  padding: 0 0 0 0;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 15px;
  letter-spacing: 0 !important;
  background: transparent;
}

.mainSubMenu .msmFdivi h5 {
  height: 13px;
  padding-left: 7px;
  margin-bottom: 10px;
  margin-left: 9px;
  font-size: 15px;
  background: url("/new/img/top/blt_colron.png") no-repeat 0 5px;
}

.mainSubMenu .msmFdivi ul {
  float: left;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  margin-left: 0;
}

.mainSubMenu .msmFdivi ul li {
  margin-bottom: 5px;
  margin-left: 9px;
  /*width:100%;*/
}

.mainSubMenu .msmFdivi ul li .LectListLeft {
  float: left;
  width: 70%
}

.mainSubMenu .msmFdivi ul li .LectListRight {
  float: right;
  width: 30%
}

.mainSubMenu .msmFdivi ul li a {
  display: block;
}

.mainSubMenu .msmFdivi ul li a:hover,
.mainSubMenu .msmFdivi ul li a:active {
  color: #000 !important;
  background: #e8decf !important;
}

.top-menu-additional a {
  color: #4d4d4d;
}

.top-menu-additional a:hover {
  color: #F63d88 !important;
}

.snb_navigation {
  z-index: 2000;
}


/* 통합검색 */
#search_section {
  zoom: 1;
  height: 97px;
  background-color: white;
}

#search_section .search_area {
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto
}

#search_section .total_search {
  position: absolute;
  left: 200px;
  top: 25px;
  width: 365px;
  padding: 6px 40px 6px 12px;
  border: 2px solid #F63D8B;
  background-color: #FFF;
}

#search_section .total_search .search_keyword {
  height: 18px;
  width: 365px;
  padding-top: 2px;
  border: 0 none;
  font-size: 15px;
  font-weight: bold;
}

#search_section .total_search .btn_total_search {
  display: block;
  position: absolute;
  top: 7px;
  right: 8px;
  width: 25px;
  height: 23px;
  background: url('https://www.doyac.com/renewal_doyac/img/btn_set.png') no-repeat 5px 2px
}

#search_section .searchLayer02 {
  position: absolute;
  z-index: 1000;
  top: 32px;
  left: -2px;
  width: 417px;
  border: 2px solid #F63D8B;
  background-color: #FFF
}

#search_section .searchLayer02 table {
  margin-left: 0 !important
}


#search_section .neighbor {
  overflow: hidden;
  position: absolute;
  right: 0px;
  top: 54px;
}

#search_section .neighbor li {
  float: left;
  line-height: 18px;
}

#search_section .neighbor li a {
  padding: 0 17px;
  font-size: 15px;
  font-weight: 600;
  background: url('https://www.doyac.com/renewal_doyac/img/line_height_01.gif') no-repeat right 40%;
  color: #4d4d4d;
  letter-spacing: 0;
  line-height: 20px
}

#search_section .neighbor li a:hover {
  color: #F63D8B;
  text-decoration: none;
}

#search_section .neighbor li.first-child a {
  padding: 0 15px 0 0
}

#search_section .neighbor li.last-child a {
  padding: 0 1px 0 17px;
  background: none
}

#search_section h1 {
  position: absolute;
  left: 10px;
  top: 15px;
  cursor: pointer
}

#search_section h1 #GnbHntHomeA img {
  margin: 0px 5px 0 0
}

@media (max-width: 1700px) {
  #desktop-popup-wrapper {
    width: 100% !important;
  }

  #desktop-popup {
    left: 0 !important;
  }
}

.m_list li {
  padding-bottom: 3px;
}

.pull-left>img {
  margin-left: 3px;
}

.LectListLeft>img {
  margin-left: 3px;
}

.top_picture_list {
  position: absolute;
  top: 350px;
}

@media (max-width: 1435px) {
  .skyBanner {
    display: none;
  }

  #hotitem_bg {
    display: none;
  }
}

@media (min-width: 1436px) {
  .skyBanner {
    display: block;
  }

  #hotitem_bg {
    display: block;
  }
}

@media (max-width: 800px) {
  .header {
    display: none;
  }

  #search_section {
    display: none;
  }
}
</style>
