<template>
  <div v-if="getCount && getCount > 0" class="recentCheckedFrame hiddenPrintArea" :class="isMainFixed ? (siteName === '레슨올' ? 'top-[80px]' : 'top-[185px]') : ''">
    <v-btn v-if="isShow" @click="isShow = false" icon color="black" size="x-small"
      class="absolute right-[140px]" :class="siteName === '레슨올' ? 'top-[176px]' : 'top-[90px]'">
      <v-icon class="text-sm" icon="far fa-chevron-right" />
    </v-btn>
    <v-btn v-else @click="isShow = true" icon color="black" size="x-small" class="absolute right-[30px]" :class="siteName === '레슨올' ? 'top-[176px]' : 'top-[90px]'">
      <v-icon class="text-sm" icon="far fa-clock-rotate-left" />
    </v-btn>

    <Transition name="slide-fade">
      <div v-if="isShow" class="absolute bg-white right-0" :class="siteName === '레슨올' ? 'top-44' : 'top-20'"
        style="border: 1px solid #ddd;  border-radius: 0 0 10px 10px;">
        <div>
          <div class="w-full bg-neutral-200 p-2 flex justify-center">
            <MiscDYLink to="/mypage/recentChecked" class="text-center text-sm">{{ siteName === '레슨올' ? '최근 본 요청서' : '최근 본 상품' }}<span class="underline underline-offset-4">({{ getCount
                }})</span></MiscDYLink>
          </div>

          <div class="p-1">
            <div class="flex w-full justify-center">
              <v-btn @click="recentCheckedStore.removeRecentChecked" class="my-1 shadow-none border" size="small"
                style="width: 90%;">모두 지우기</v-btn>
            </div>

            <div v-for="(item, key) in recentCheckedStore.getRecentChecked(4)" :key="key" class="item mb-3">
              <div v-if="key !== 0" ></div>

              <MiscDYLink :to="item.toSrc">
                <DYImg :src="item.imgSrc" class="!rounded-md m-1" height="70px" cover />
                <div class="text-xs m-2 line-clamp-2" v-html="item.title"></div>
              </MiscDYLink>
            </div>
          </div>
        </div>

      </div>
    </Transition>
  </div>
</template>

<script setup lang='ts'>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { useDYRecentCheckedStore } from '~/modules/nuxt3-module-doyac/stores/recent-checked';

library.add(faChevronRight, faChevronLeft, faClockRotateLeft)

const recentCheckedStore = useDYRecentCheckedStore()
const route = useRoute()
const {siteName} = useDYAppConfig()

// ------------------ 인터페이스 ------------------

// ------------------ 데이터 ------------------

const isShow = ref(true)
const windowScrollY = ref()

// ------------------ 컴퓨티드 ------------------

const getCount = computed(() => {
  const recent = recentCheckedStore.getRecentChecked()
  if (!recent) return 0

  return recentCheckedStore.getRecentChecked()?.length
})

const isMainFixed = computed(() => {
  return route.path === '/' && windowScrollY.value < 101
})

// ------------------ 메서드 ------------------

// ------------------ 훅 ------------------

onMounted(() => {
  windowScrollY.value = document.documentElement.scrollTop
  document.addEventListener('scroll', () => {
    windowScrollY.value = document.documentElement.scrollTop
  })
})

</script>

<style scoped lang='scss'>
.item {
  position: relative;
  background-color: #fff;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 110px;
}

.recentCheckedFrame:deep() {
  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
    // transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
}
</style>