<template>
  <a :href="computedHref" :to="computedTo" @click="goToLoginPage">
    <slot>로그인</slot>
  </a>
</template>

<script setup lang="ts">
const router = useRouter()
const route = useRoute()

const props = withDefaults(defineProps<{
  to? :string,
  useHref? : boolean
}>(),{
  to: () => '/login',
  useHref: () => false,
})

const computedHref = computed(()=>{
  return props.useHref ? props.to : undefined
})

const computedTo = computed(()=>{
  return props.useHref ? undefined : props.to
})

const goToLoginPage = () => {
  saveCurrentUrl()
  if(computedTo.value) {
    router.push(computedTo.value)
  } else if(computedHref.value) {
    window.location.href = computedHref.value
  } else {
    router.push('/login')
  }
}

const saveCurrentUrl = () => {
  const redirect = useCookie('auth.redirect')
  redirect.value = route.fullPath
}

</script>