<template>
  <div v-if="message && message.text" class="container ">
    <ModalDYDialog v-model:dialog-control="modalOpen" width="320px" 
      :max-height="useWindow.windowHeight - 100 + 'px'" class="web-message-modal" :is-closable-by-back="false"
      :has-scroll="false" :class="message.visiblePosition ? message.visiblePosition.toLowerCase() : ''"
      style="z-index: 1000;" type="instant">
      <template #header>
        <div :style="`background-color: ${headerColor}; color: white; position: initial; padding: 14px; width: 100%;`">
          <h4 id="myModalLabel" class="modal-title fontW500" style="text-align: left;">
            <template v-if="message.priority === 'high'">
              <v-icon icon="far fa-envelope-open" style="font-size: 22px" /> 새 중요 메시지
            </template>
            <template v-else>
              <v-icon icon="far fa-envelope-open" style="font-size: 22px" /> 새 메시지
            </template>
          </h4>

          <button type="button" class="close instantMessageDelButton" aria-label="Close"
            style="opacity: 0.8; color: white;" :style="`padding: 14px;`" @click="deleteMessage()">
            <v-icon icon="far fa-times" style="font-size: 18px" />
          </button>
        </div>
      </template>

      <div class="webModalContent p-4 overflow-y-auto mb-12" :style="{maxHeight:useWindow.windowHeight - 200 + 'px'}">
        <div class="overflow-y-auto">
          <span class="instantMessageTitleDisplay fontW500"
            style="display: block; font-size: 17px; margin-bottom: 10px; text-align: left;">
            [{{ subject }}]
          </span>
          <span v-if="imageUrl && imageUrl.length > 0" class="instantMessageimgDisplay" style="margin-bottom: 10px;">
            <DYImg v-for="(image, key) in imageUrl" :key="key" :src="image" style="max-width: 100%;" />
          </span>
          <DYImg v-if="message.systematizedName === 'GGIM'"
            src="https://www.lessonall.com/renewal_gawe/img/study_ask_800.jpg" class="mb-3" />
          <p style="text-align: left; word-break: break-all; white-space: pre-line">
            <template v-if="message.priority === 'low' && message.unreadCount > 1">
              읽지 않은 메시지가 <span class="fontW500" style="color:red;">{{ message.unreadCount }}건</span> 있습니다.
            </template>
            <template v-else>

              {{ message.text }}
            </template>
          </p>
        </div>
      </div>

      <div class="fixed bottom-0 w-full">
        <div v-if="message.visibleOnWeb && message.buttonName" id="LEFTbuttonTwo">
          <div class="balloon_button_two" style="background-color: #6c6c6c;">
            <MiscDYLink class="instantMessageDelButton" :href="url.includes('/v/') ? undefined : url"
              :to="url.includes('/v/') ? url.replace(fullDomain + '', '').replace('/v/', '/') : undefined" data-delNo=""
              @click="deleteMessage()">
              자세히 보기
            </MiscDYLink>
          </div>
          <div class="balloon_button_two" style="background-color: #d14646;">
            <MiscDYLink class="customButton instantMessageDelButton" :href="url.includes('/v/') ? undefined : url"
              :to="url.includes('/v/') ? url.replace(fullDomain + '', '').replace('/v/', '/') : undefined"
              @click="deleteMessage()">
              <span>{{ buttonName }}</span>
            </MiscDYLink>
          </div>
        </div>
        <div v-else-if="message.visibleOnWeb || message.buttonName" class="balloon_button_one"
          style="background-color: #d14646;">
          <MiscDYLink class="customButton instantMessageDelButton" :href="url.includes('/v/') ? undefined : url"
            :to="url.includes('/v/') ? url.replace(fullDomain + '', '').replace('/v/', '/') : undefined"
            style="padding: 10px 60px;" @click="deleteMessage()">
            {{ buttonName }}
          </MiscDYLink>
        </div>
      </div>


    </ModalDYDialog>
  </div>
</template>

<script setup lang='ts'>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelopeOpen, faTimes } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { extractDataOrFailure, useDYFetchWithToken } from '~/modules/nuxt3-module-doyac/composables/fetch';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';

const router = useRouter()
const route = useRoute()
const useWindow = useDYWindowStore()
const { fullDomain, siteName } = useDYAppConfig()

library.add(faEnvelopeOpen, faTimes)

// -------------------------인터페이스

interface InstantMessage {
  buttonName: string,
  custom1: string, custom2: string, systematizedName: string, requestTime: string, senderName: string,
  file1: string, file2: string, file3: string, file4: string, file5: string, no: number, priority: string, subject: string, text: string, unreadCount: number, url: string, visibleOnWeb: number, visiblePosition: 'RIGHT' | 'LEFT'
}

// -------------------------데이터

const message = ref<InstantMessage>()
const modalOpen = ref(false)

// -------------------------컴퓨티드

const buttonName = computed(() => {
  return (!message.value || !message.value.buttonName) ? '자세히 보기' : message.value.buttonName
})

const headerColor = computed(() => {
  if (!message.value) { return '#29814e' }
  switch (message.value.priority) {
    case 'high': return '#ec0000'
    case 'middle': return '#5f44e0'
    case 'low': return '#29814e'
    default: return '#29814e'
  }
})

const imageUrl = computed(() => {
  const imageArr: string[] = []

  if (message.value) {
    if (message.value.priority === 'low' && message.value.unreadCount > 1) imageArr.push('/renewal_doyac/img/message_package_800.jpg')
    if (message.value.file1) imageArr.push(message.value.file1.replace('http://', 'https://'))
    if (message.value.file2) imageArr.push(message.value.file2.replace('http://', 'https://'))
    if (message.value.file3) imageArr.push(message.value.file3.replace('http://', 'https://'))
    if (message.value.file4) imageArr.push(message.value.file4.replace('http://', 'https://'))
    if (message.value.file5) imageArr.push(message.value.file5.replace('http://', 'https://'))
  }
  return imageArr
})

const maxHeightPx = computed(() => {
  return `${(useWindow.windowHeight - 200)}px`
})

const subject = computed(() => {
  if (message.value) {
    if (message.value.priority === 'low' && message.value.unreadCount > 1) return '새로운 메시지가 도착했습니다'
    return message.value.subject
  }
  return ''
})

const dayFormat = computed(() => {
  if (message.value) {
    return dayjs(message.value.requestTime).format('YYYY-MM-DD')
  }

  return ''
})

const url = computed(() => {
  // 레슨올도 신페이지로 쪽지함이 바뀌면 고쳐야 함
  const defaultUrl = siteName === '레슨올' ? '/mer/milk/messageBox' : '/v/mypage/messageBox'

  return (!message.value || !message.value.url) ? defaultUrl : message.value.url
})

// -------------------------메서드

const deleteMessage = async (autoClose = true) => {
  if (message.value) {
    if (autoClose) { modalOpen.value = false }

    await useDYFetchWithToken<{ success: boolean }>(`/mer/api/web-messages/${message.value.no}`, { method: 'delete' })
  }
}

const getMessage = async () => {
  try { //가져올 인스턴트 메시지가 있는지 체크해서 있으면 띄움
    const res = await useDYFetchWithToken<InstantMessage>(siteName === '레슨올' ? '/mer/api/gawe-web-messages/recent' : '/mer/api/web-messages/recent', { method: 'get' })
    message.value = extractDataOrFailure(res)

    nextTick(() => {
      if (message.value) modalOpen.value = true
    })
  } catch (e: any) {

  }
}

// -------------------------훅

onMounted(async () => {
  await nextTick()
  getMessage()
})

if (process.client) {
  watch(() => route.path, async (value) => {
    if (value) {
      modalOpen.value = false
      nextTick(() => {
        getMessage()
      })
    }
  })
}

</script>

<style lang="scss">
.web-message-modal .close {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  outline: 0px;
}

/* .web-message-modal .webModalContent {
  // position: fixed;
  margin: auto;
  width: 320px;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
} */

.web-message-modal.modal.leftModal .modal-content,
.web-message-modal.modal.right .modal-content {
  /*height: 100%;*/
  /* // overflow-y: initial; */
}

.web-message-modal.modal.leftModal .modal-body,
.web-message-modal.modal.right .modal-body {
  padding: 15px;
}

/* ----- MODAL STYLE ----- */
.web-message-modal .modal-content {
  border-radius: 0;
  border: none;
}

.web-message-modal .modal-header {
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
}

/*.demo {*/
/*padding-top: 60px;*/
/*padding-bottom: 110px;*/
/*}*/

.web-message-modal h4 {
  padding: 0;
  font-size: 18px;
  color: white;
}

.web-message-modal .webModalContent {
  bottom: 0;
}

.web-message-modal .balloon_button_one {
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  background-color: #49d2de;
}

.web-message-modal .balloon_button_one a {
  padding: 15px 5px;
  display: block;
}

.web-message-modal .balloon_button_one a:link {
  color: #ffffff;
}

.web-message-modal .balloon_button_one a:visited {
  color: #ffffff;
}

.web-message-modal .balloon_button_two {
  margin-top: 10px;
  display: inline-block;
  width: 49%;
  background-color: #49d2de;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.web-message-modal .balloon_button_two a {
  padding: 14px 3px;
  display: block;
}

.web-message-modal .balloon_button_two a:link {
  color: #ffffff;
}

.web-message-modal .balloon_button_two a:visited {
  color: #ffffff;
}

// 뭐가 꼬였는지 모르겠으나 deep이 먹지 않음..
.web-message-modal .v-overlay__content {
  position: fixed !important;
  bottom: 0 !important;
}

.web-message-modal.right .v-overlay__content {
  right: 0 !important;
  margin-right: 0px;
}

.web-message-modal.left .v-overlay__content {
  left: 0 !important;
  margin-left: 0px;
}
</style>