import MobileDetect from "mobile-detect"
import { useDYAppConfig } from "~~/modules/nuxt3-module-doyac/composables/config"
import { extractDataOrFailure, useDYFetch } from "~~/modules/nuxt3-module-doyac/composables/fetch"
import { findIpForSSR, getQueryObject } from "~~/modules/nuxt3-module-doyac/libs/utils"
import { useDYUserStore } from "../stores/user"
import dayjs from "dayjs"

//한번만 일어나야 하는 미들웨어의 개념에서의 처리(실제 서버 미들웨어에서 하지 않을지라도)
export function DYServerMiddlewareManager() { //기본적으로 전부 서버사이드에서 사용할 것(ssrContext 때문)
  const { fullDomain } = useDYAppConfig()
  const { ssrContext } = useNuxtApp()
  const headers = useRequestHeaders()

  const router = useRouter()

  const checkIp = async () => {
    const ip = findIpForSSR(ssrContext?.event.node.req)

    try {
      const res = await useDYFetch<any>(
        `${fullDomain}/mer/api/check-ip`,
        { method: 'GET', params: { ip, api: 'new' } }
      )
      extractDataOrFailure(res, true)
    } catch (e: any) {
      if (e.statusCode === 403) {
        if (e.data?.redirect) {
          await router.push(`${e.data?.redirect}`)
        } else {
          await router.push('/error')
        }
      }
    }
    return
  }

  const makeSSRStatisticsData = () => {
    const context = ssrContext?.event.node
    if (!context) return

    const userAgent = context.req.headers['user-agent']
    let route = 'pc'
    if (userAgent !== undefined) {
      const md = new MobileDetect(userAgent)
      route = !md.mobile() ? 'pc' : 'mobile'
    }

    const userStore = useDYUserStore()
    const ip = findIpForSSR(context.req)

    if (context.req.headers.accept && context.req.headers.accept.includes('text/html') && context.req.headers.referer) {
      const refererData = context.req.headers.referer ? context.req.headers.referer.replace(/\?$/, '') : ''

      try {
        userStore.ssrStatisticsData = {
          referer: refererData, ip: ip, userAgent, route, query: getQueryObject(context.req.url ?? '')
        }
      } catch (e) {
        console.log(e)
      }
    } else { // 리퍼러가 없는 경우에도 현재 쿼리 기반으로도 데이터가 들어갈 수 있도록
      try {
        userStore.ssrStatisticsData = {
          referer: '', ip: ip, userAgent, route, query: getQueryObject(context.req.url ?? '')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const sendSSRStatistics = () => {
    const userStore = useDYUserStore()
    const {data} = useDYFetch<string|null>('/mer/api/statistics/referer', { method: 'put', body: userStore.ssrStatisticsData })

    if (data.value) {
      const date = dayjs()
      const day30a = date.add(30,'day')

      const simsLogAdPk = useCookie('simsLogAdPk', {
        sameSite: 'none',
        secure: true,
        expires: day30a.toDate(), // 30일간 남김
        path: '/' // 이렇게 안하면 /v로 붙게 되는데 그 경우 구 프레임워크에선 못 쓰게 되므로
      })

      simsLogAdPk.value = data.value

      const simsLogAdInsertedTime = useCookie('simsLogAdInsertedTime', {
        sameSite: 'none',
        secure: true,
        expires: day30a.toDate(), // 30일간 남김
        path: '/' // 이렇게 안하면 /v로 붙게 되는데 그 경우 구 프레임워크에선 못 쓰게 되므로
      })

      simsLogAdInsertedTime.value = ''+date.unix
    }
  }

  return {
    checkIp, makeSSRStatisticsData, sendSSRStatistics
  }
}