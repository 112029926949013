<!-- 임플 단계에서의 레이아웃 디폴트 -->

<template>
  <DYDefault>
    <template #topLine>
      <DYTopLine>
        <li id="tnb_hana_child" :class="route.path.includes('/requestForm/teacher') ? 'first-child' : ''">
          <MiscDYLink to="/requestForm/teacher/list">전문가</MiscDYLink>
        </li>
        <li :class="route.path.includes('/requestForm/student') ? 'first-child' : ''">
          <MiscDYLink to="/requestForm/student/list">요청서</MiscDYLink>
        </li>
      </DYTopLine>
    </template>

    <template #actionbar>
      <DYActionbar :data="{ existTopLine: true, hasShortCut: true }">
        <template #shortcutMenu="{ text }">
          <div v-if="text === '전문가/학생 등록'" class="bubble_tip"
            style="display: block;position: absolute;background:#EC8CA9;width: 50px;top:0px;">
            <div class="bubble_text">회원가입</div>
          </div>
        </template>
      </DYActionbar>
    </template>

    <!-- 라이트 패널 안에 있는 내용들 -->
    <template #rightPanelImpl="slotProps">
      <li v-if="slotProps.selectedMenu === 1" class="active" style="position:relative;">
        <ul class="depth_2">
          <li>
            <MiscDYLink to="/requestForm/teacher/list">
              전문가 찾기
            </MiscDYLink>
          </li>
          <ul class="depth_3">
            <li>
              <MiscDYLink to="/requestForm/teacher/list">
                전문가 전체보기
              </MiscDYLink>
              <!-- <ul class="depth_3">
                  <li>
                    <MiscDYLink to="/requestForm/teacher/list?verification=1">
                      검증 전문가 보기
                    </MiscDYLink>
                  </li>
                  <li>
                    <MiscDYLink to="/requestForm/teacher/list?hasPracticeRoom=1">
                      연습실 보유 선생님 보기
                    </MiscDYLink>
                  </li>
                </ul> -->
            </li>
          </ul>
        </ul>
        <div class="clear"></div>
        <div style="position:absolute;bottom:50px;width:100%;padding-right: 24px;">
          <v-btn color="blue" style="width:100%;"
            onclick="location.href='/mer/mypage/requestFormList?formType=teacher'">전문가 등록/관리</v-btn>
        </div>
      </li>

      <li v-if="slotProps.selectedMenu === 2" class="active" style="position:relative;">
        <ul class="depth_2">
          <li>
            <MiscDYLink to="/requestForm/student/list">등록된 요청서 보기</MiscDYLink>
            <ul class="depth_3">
              <li>
                <MiscDYLink to="/requestForm/student/list">요청서 전체보기</MiscDYLink>
              </li>
            </ul>
          </li>
        </ul>
        <div class="clear"></div>
        <div style="position:absolute;bottom:50px;width:100%;padding-right: 24px;">
          <v-btn color="blue" style="width:100%;" onclick="location.href='/mer/mypage/requestFormList'">내 요청서
            등록/관리</v-btn>
        </div>
      </li>

      <li v-if="slotProps.selectedMenu === 3" class="active">
        <ul class="depth_2">
          <li>
            <a href="https://www.lessonall.com/renewal/practiceroom/list.php">연습실 전체보기
              <v-icon icon="fal fa-chevron-right" class="topRightBtn fa-lg"></v-icon>
            </a>
            <ul class="depth_3">
              <li>
                <a
                  href="https://www.lessonall.com/renewal/practiceroom/list.php?service=%EC%9D%8C%EC%95%85%EC%A2%85%ED%95%A9">음악
                  연습실</a>
              </li>
              <li>
                <a
                  href="https://www.lessonall.com/renewal/practiceroom/list.php?service=%EB%AF%B8%EC%88%A0%EC%A2%85%ED%95%A9">미술
                  연습실</a>
              </li>
              <li>
                <a
                  href="https://www.lessonall.com/renewal/practiceroom/list.php?service=%EB%AC%B4%EC%9A%A9%EC%A2%85%ED%95%A9">무용
                  연습실</a>
              </li>
              <li>
                <a
                  href="https://www.lessonall.com/renewal/practiceroom/list.php?service=%EC%8A%A4%ED%8A%9C%EB%94%94%EC%98%A4">스튜디오</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </template>

    <notifications group="requestFormUpdate">
      <template #body="props">
        <div class="my-notification opacity-90 hiddenPrintArea" @click="props.close"
          style="margin: 0 5px 5px;  padding: 10px;  font-size: 12px;  color: white; background: #2196F3; border-left-color: #f48a06;">
          <div v-if="hasFormCount" class="flex flex-wrap justify-end">
            <p class="col-12 title text-base">
              로그인으로 인해 목록에서 상단으로 끌어올리기 되었습니다!
            </p>
            <!-- <div class="col-12 flex justify-end text-base">끌어올리기 되었습니다!</div> -->
          </div>

          <div v-else class="flex flex-wrap justify-end">
            <p class="col-12 title text-base">
              현재 등록된 요청서가 없습니다. 요청서를 등록하고 레슨올의 회원들을 만나보세요.
            </p>

            <div class="col-12 flex justify-end mt-5">
              <v-btn @click="moveRegForm">요청서 작성하기</v-btn>
              <!-- <MiscDYLink class="btn" href="/mer/milk/selectCategory">요청서 작성 하기</MiscDYLink> -->
            </div>
          </div>
        </div>
      </template>
    </notifications>


    <slot></slot>
  </DYDefault>
</template>

<script setup lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import DYDefault from '~~/modules/nuxt3-module-user-doyac/layout/DYDefault.vue';
import { useGWUserScrapManager } from '~/modules/nuxt3-module-gawe/composables/gw-scraps';
import { useGWScrapStore } from '~/modules/nuxt3-module-gawe/stores/scrap';
import { useGWRequestForms } from '~/modules/nuxt3-module-gawe/composables/gw-request-forms';
import { notify } from '@kyvg/vue3-notification';
import { useUtils } from '~/modules/nuxt3-module-doyac/composables/utils';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';

library.add(faChevronRight)

const userStore = useDYUserStore()
const route = useRoute()
const router = useRouter()
const { getMyScrapList } = useGWUserScrapManager()
const { hasForm } = useGWRequestForms()
const { notifyError } = useUtils()

const firstLoginCheck = ref(false)
const hasFormCount = ref(false)

const moveRegForm = () => {
  location.href = '/mer/milk/selectCategory'
}

const notifyFirstLogin = async () => {
  if (firstLoginCheck.value) return

  try {
    const result = await hasForm()
    hasFormCount.value = result.success

    notify({
      group: 'requestFormUpdate',
      duration: 5000,
      closeOnClick: true
    })

    firstLoginCheck.value = true
  } catch (e: any) {
    console.log('노티에러', e.message)
  }
}

onMounted(async () => {
  await nextTick()
  await userStore.ready()
  if (userStore.isSignedIn) {
    try {
      getMyScrapList().then() //이걸 로그인하는 순간에만 해주면 로그인 된 상태에서 새로고침했을 떄에는 스크랩 스토어 정보가 없음
    } catch(e:any) {
      console.error(e)
      notifyError(e)
    }
  }
})

watch(() => userStore.isSignedIn, async (val) => {
  if (val) { // 로그인 한 순간 해야할 일
    try {
      getMyScrapList().then()
    } catch(e:any) {
      console.error(e)
      notifyError(e)
    }
    
    try {
      notifyFirstLogin().then()
    } catch(e:any) {
      console.error(e)
      notifyError(e)
    }
    
  } else { // 로그아웃
    const useScrapStore = useGWScrapStore()
    useScrapStore.scrapList = undefined
  }
})

// watch(() => route.query, async () => {
//   if (userStore.isSignedIn) {
//     notifyFirstLogin().then()
//   }
// })

</script>

<style>
.header .tnb .tnb_hana li {
  float: left;
  border-right: 1px solid #D1D1D1;
}

.header .tnb .tnb_hana li:first-child {
  border-left: 1px solid #D1D1D1;
}

.header .tnb .tnb_hana li a {
  display: block;
  width: 98px;
  height: 18px;
  padding: 7px 0 6px;
  border-bottom: 1px solid #D1D1D1;
  font-size: 13px;
  font-weight: 600;
  background-color: #F2F2F2;
  background-image: none;
  text-align: center;
  color: #444;
  line-height: 18px;
  box-sizing: content-box;
}

.header .tnb .tnb_hana li.first-child a,
.header .tnb .tnb_hana li a:hover {
  border-bottom: 1px solid #FFF;
  background-color: #FFF;
  color: #c83d67;
  text-decoration: none;
}

/* 라이트패널 CSS 시 */
.gnb .scroll_box>ul>li {
  padding: 0 12px;
  padding-bottom: 50px;
  display: none;
  min-height: 100%;
  box-sizing: border-box;
}

.gnb .scroll_box>ul>li.active {
  display: block;
}

.gnb .scroll_box .depth_2 {
  padding: 10px 0;
}

.gnb .scroll_box .depth_2>li>a {
  display: block;
  position: relative;
  padding: 21px 15px 15px 0;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #00907f;
  font-weight: bold;
  font-size: 14px;
}

.gnb .scroll_box .depth_2>li:first-child>a {
  padding-top: 15px;
}

.gnb .scroll_box .depth_2 .topRightBtn {
  display: block;
  position: absolute;
  top: 15px;
  right: 0;
  width: 6px;
  height: 10px;
  float: right;
  font-size: 13px;
}

.gnb .scroll_box .depth_2>li:first-child>a[href]:after {
  top: 23px;
}

.ios .gnb .scroll_box .depth_2>li>a[href]:after {
  top: 28px;
}

.gnb .scroll_box .depth_3 {
  padding-top: 10px;
}

.gnb .scroll_box .depth_3>li>a {
  padding: 6px 0 9px 6px;
  display: block;
  position: relative;
  font-size: 14px;
  color: #222;
}

.gnb .scroll_box .depth_3>li.toggle>a {
  padding-right: 20px;
}

.gnb .scroll_box .depth_3 .plusBtnDepth3 {
  font-size: 14px;
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
  width: 14px;
  height: 14px;
  color: #00907f;
}

.gnb .scroll_box .depth_3>li.toggle.active>a {
  font-weight: bold;
}

@media (min-width: 450px) {
  .gnb {
    width: 450px;
  }

  .gnb .scroll_box>ol li a {
    padding: 15px 10px 15px 15px;
    font-size: 16px;
  }

  .gnb .scroll_box .depth_2>li>a {
    font-size: 16px;
  }

  .gnb .scroll_box .depth_3>li>a {
    font-size: 15px;
  }

  .gnb .scroll_box .depth_4>li>a {
    font-size: 14px;
  }
}

/* 라이트패널 CSS 종 */

.bubble_tip {
  position: relative;
  width: 120px;
  height: 18px;
  padding: 0px;
  background: #bc0c0f;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  margin-bottom: 8px;
}

.bubble_text {
  color: #ffffff;
  text-align: center;
  font-size: 11px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  top: 0px;
}

.bubble_tip:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 3px 3px 0;
  border-color: #EC8CA9 transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -2px;
  left: 20px;
}
</style>