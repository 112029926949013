<template>
  <div>
    <ModalDYDialog :title-icon="faStar" v-model:dialog-control="modalOpen" title="앱으로 이동하기" type="small">
      <div class="flex flex-wrap justify-center">
        <v-col cols="12">
          <v-alert variant="outlined" color="blue" class="text-left">
            앱 이용시 로그인상태 연동과 함께 더 빠르고 편리한 서비스 이용이 가능합니다.
          </v-alert>
        </v-col>
        <p class="col-12 flex justify-center" style="padding-top:20px;padding-bottom: 20px;">
          <v-btn @click="goAppLink" color="blue" class="btn btn-bs3-lg btn-bs3-primary w-11/12">
            <v-icon icon="far fa-location-arrow" class="mr-2" style="font-size: 18px;top: 2px; position: inherit;" /> 앱으로 이동하기
          </v-btn>
        </p>
        <p class="col-12 flex justify-center">
          <v-btn class="btn btn-bs3-lg btn-bs3-warning w-11/12" color="warning" @click="modalOpen = false">
            <v-icon icon="far fa-times" class="mr-2" style="font-size: 16px;top: 2px; position: inherit;" /> 일단 웹으로 보기
          </v-btn>
        </p>
      </div>
    </ModalDYDialog>
  </div>
</template>

<script setup lang='ts'>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationArrow, faStar, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { appLink } from '~/modules/nuxt3-module-doyac/libs/utils';

const route = useRoute()
const { fullDomain, basePath } = useDYAppConfig()

library.add(faStar, faLocationArrow, faTimes)

// -------------------------인터페이스

// -------------------------데이터

const modalOpen = ref(false)

// -------------------------컴퓨티드

// -------------------------메서드

const goAppLink = () => {
  appLink(`${fullDomain}${basePath}${route.fullPath}`)
}

// -------------------------훅

onMounted(() => {
  modalOpen.value = true
})

</script>

<style lang='scss' scoped>
//
</style>